import {
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'react-i18next';

import { EmployeeCollection } from '../../../types/api/employees';
import { TableColumns, Unpacked } from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { withTrailingEllipsis } from '../../../utils/helpers/formatting';
import OverlayHoverPanel from '../../DataTable/OverlayHoverPanel/OverlayHoverPanel';
import { LogEntry, LogSeverity } from './Logs.types';

export type EmployeeOption = Pick<
  Unpacked<EmployeeCollection>,
  'id' | 'korisnik_id' | 'ime' | 'prezime'
>;

type Row = LogEntry;
type Column = TableColumns<
  Pick<
    Row,
    | 'date'
    | 'severity_id'
    | 'module'
    | 'user_agent'
    | 'message'
    | 'ip_address'
    | 'user_name'
  >
>;

export const tableStorageKey = 'logs_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    severity_id: t('Type'),
    date: t('Date'),
    module: t('Module'),
    user_agent: t('Computer name'),
    message: t('Message'),
    ip_address: t('IP address'),
    user_name: t('Employee name'),
  };
}

export function additionalColumnProperties(t: TFunction, column: Column) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'date':
      return {
        body: (row: Row) => dateCell(row[column] ?? ''),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'severity_id':
      return {
        body: (row: Row) => {
          const value = row[column];

          return (
            <span
              data-tip={
                value === LogSeverity.Error
                  ? t('Error')
                  : value === LogSeverity.Warning
                  ? t('Warning')
                  : t('Info')
              }
              data-for="tooltip-right"
            >
              <FontAwesomeIcon
                icon={
                  value === LogSeverity.Error
                    ? faTimesCircle
                    : value === LogSeverity.Warning
                    ? faExclamationTriangle
                    : faInfoCircle
                }
                color={
                  value === LogSeverity.Error
                    ? 'var(--red-500)'
                    : value === LogSeverity.Warning
                    ? 'var(--yellow-500)'
                    : 'var(--blue-500)'
                }
              />
            </span>
          );
        },
        style: { width: 100, maxWidth: 110 },
        bodyStyle: {
          width: 100,
          maxWidth: 110,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'user_agent':
      return {
        body: (row: Row) =>
          typeof row[column] === 'string' && row[column]!.trim() ? (
            <OverlayHoverPanel fullText={row[column]}>
              {withTrailingEllipsis(row[column]!, 0, 25)}
            </OverlayHoverPanel>
          ) : (
            emptyCell
          ),
        style: { width: 200 },
        bodyStyle: { width: 200, whiteSpace: 'nowrap' },
      };

    case 'message':
      return {
        body: (row: Row) =>
          typeof row[column] === 'string' && row[column]!.trim() ? (
            <OverlayHoverPanel fullText={row[column]}>
              {withTrailingEllipsis(row[column]!, 0, 50)}
            </OverlayHoverPanel>
          ) : (
            emptyCell
          ),
        style: { width: 450 },
        bodyStyle: { width: 450, whiteSpace: 'nowrap' },
      };

    case 'ip_address':
    case 'module':
    case 'user_name':
      return {
        style: { width: 150 },
        bodyStyle: { width: 150 },
      };

    default:
      return { style: { width: 150, height: 100 }, bodyStyle: { width: 150 } };
  }
}

export function getRowColor(row: Row): object {
  return {
    red: row.severity_id === LogSeverity.Error,
    yellow: row.severity_id === LogSeverity.Warning,
    blue: row.severity_id === LogSeverity.Info,
  };
}
