import {
  IconDefinition,
  faCheckCircle,
  faFileContract,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'react-i18next';

import { ReturnDocument } from '../../../../enums/orders';
import { EmployeeCollection } from '../../../../types/api/employees';
import { Numeric } from '../../../../types/general';
import { Unpacked } from '../../../../types/util';
import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { currencyFormat } from '../../../../utils/helpers/formatting';
import Tooltip from '../../../Misc/Tooltip/Tooltip';

export type EmployeeOption = Pick<
  Unpacked<EmployeeCollection>,
  'id' | 'ime' | 'prezime' | 'korisnik_id'
>;

export type Order = {
  id: Numeric;
  klient_od_ime: string | null;
  klient_do_ime: string | null;
  adresa_od: string | null;
  adresa_do: string | null;
  otkup: Numeric;
  cena: Numeric;
  vrednost: Numeric;
  komentar: string | null;
  tezina: string | null;
  vremenska_ramka?: string | null;
  povraten_dokument: ReturnDocument;
  volumen?: string | null;
  seriski_broj: string;
};

export const tableStorageKey = 'deletedOrders_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    seriski_broj: t('Serial Number'),
    klient_od_ime: t('Sender Name'),
    adresa_od: t('Sender Address'),
    klient_do_ime: t('Recipient Name'),
    adresa_do: t('Recipient Address'),
    vremenska_ramka: t('Time Frame'),
    tezina: t('Weight'),
    volumen: t('Volume'),
    cena: t('Price'),
    otkup: t('Redemption'),
    vrednost: t('Value'),
    povraten_dokument: t('Return Document'),
    komentar: t('Comment'),
  };
}

function getIcon(tooltip: string, icon: IconDefinition) {
  return (
    <Tooltip text={tooltip}>
      <FontAwesomeIcon icon={icon} />
    </Tooltip>
  );
}

export function getReturnDocIcon(t: TFunction, povraten_dokument: Numeric) {
  let icon = emptyCell;

  if (povraten_dokument == ReturnDocument.OrderIsReturnDocument) {
    icon = getIcon(t('Shipment is the return document'), faFileContract);
  } else if (povraten_dokument == ReturnDocument.HasReturnDocument) {
    return (icon = getIcon(
      t('Parcel contains return document'),
      faCheckCircle
    ));
  } else if (povraten_dokument == ReturnDocument.NoReturnDocument) {
    icon = getIcon(t('No return document'), faTimes);
  }

  return icon;
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          textAlign: 'center',
        },
      };

    case 'seriski_broj':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'adresa_od':
    case 'adresa_do':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_od_ime':
    case 'klient_do_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'vremenska_ramka':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'tezina':
    case 'volumen':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'cena':
    case 'otkup':
    case 'vrednost':
      return {
        body: (row: Order) => {
          return currencyFormat(row[column], { showCurrency: true });
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'povraten_dokument':
      return {
        body: (row: Order) => {
          return getReturnDocIcon(t, row?.povraten_dokument);
        },
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
          textAlign: 'center',
        },
      };

    case 'komentar':
      return {
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  handleHistory: () => void,
  historyGuard: boolean,
  handleCMViewOrder: () => void,
  readGuard: boolean,
  handleRecreateOrder: () => void,
  recreateGuard: boolean
) {
  let menu = [];

  if (readGuard) {
    menu.push({
      label: t('View info'),
      icon: 'fas fa-search',
      command: handleCMViewOrder,
    });
  }

  if (historyGuard) {
    menu.push({
      label: t('History'),
      icon: 'fas fa-history',
      command: handleHistory,
    });
  }

  if (recreateGuard) {
    menu.push({
      label: t('Recreate'),
      icon: 'fas fa-undo-alt',
      command: handleRecreateOrder,
    });
  }

  return menu;
}

export function generateErrorMsg(t: TFunction, recreateOrderError: string) {
  let errorMsgObj = recreateOrderError
    .replace(/[&/\\#,+().'"{}]/g, '')
    .split(':');

  return errorMsgObj[1]
    ? t('due to {{errorMessage}}', { errorMessage: errorMsgObj[1] })
    : '';
}
