import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { CountryPhone } from '../../../../../enums/countries';
import { SmartPOSTerminals } from '../../../../../types/api/smartPosTerminals';
import { Numeric } from '../../../../../types/general';
import { LabelValue } from '../../../../../types/options';
import { getPhoneorMobileNumberRegion } from '../../../../../utils/helpers/phoneNumbers';
import { yupRequiredField } from '../../../../../utils/helpers/yup';

export function smartPOSTerminalsStatuses(t: TFunction): LabelValue[] {
  return [
    { label: t('Active'), value: '1' },
    { label: t('Inactive'), value: '2' },
    { label: t('Blocked'), value: '3' },
  ];
}

export type FormFields = {
  username: string;
  activation_code: Numeric;
  pin: Numeric;
  status_id: Numeric;
  terminal_id: string;
  courierId: Numeric;
  courierName: string;
  device_id: Numeric;
  mobile_number: string;
  mobile_number_region: CountryPhone;
};

export function getDefaultValues(
  isEditDialog: boolean,
  data: SmartPOSTerminals | undefined
): FormFields {
  return {
    username: isEditDialog ? data?.username ?? '' : '',
    activation_code: isEditDialog ? data?.activationCode ?? '' : '',
    pin: isEditDialog ? data?.pin ?? 0 : 0,
    status_id: isEditDialog ? data?.statusId ?? 1 : 1,
    terminal_id: isEditDialog ? data?.terminalId ?? '' : '',
    courierName: isEditDialog ? data?.courierName ?? '' : '',
    courierId: isEditDialog ? data?.courierId ?? '' : '',
    device_id: isEditDialog ? data?.deviceId ?? 0 : 0,
    mobile_number: isEditDialog ? data?.mobile_number ?? '' : '',
    mobile_number_region: getPhoneorMobileNumberRegion(
      data?.mobile_number ?? ''
    ),
  };
}

export function toApiData(data: FormFields): Partial<FormFields> {
  let apiData: Partial<FormFields> = { ...data };

  delete apiData.mobile_number_region;
  delete apiData.courierId;
  delete apiData.courierName;

  if (!apiData.mobile_number) {
    delete apiData.mobile_number;
  }

  return apiData;
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    username: Yup.string().required(yupRequiredField(t, t('Name'))),
    activation_code: Yup.string().required(
      yupRequiredField(t, t('Activation Code'))
    ),
    pin: Yup.number().required(yupRequiredField(t, t('Pin'))),
    status_id: Yup.number().required(yupRequiredField(t, t('Status'))),
    terminal_id: Yup.string().required(yupRequiredField(t, t('Terminal ID'))),
    device_id: Yup.number().required(yupRequiredField(t, t('Device ID'))),
    mobile_number: Yup.string().optional(),
  });
}
