import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getAssignmentAndReceptionStatusSeveritySound,
  getWarehouseTransferCourierOrderAssignmentStatusConfig,
} from '../../../../../configs/orders';
import ToastContext from '../../../../../context/ToastContext';
import { IntBool } from '../../../../../enums/booleans';
import { FileTypes } from '../../../../../enums/files';
import { WarehouseAssignmentAndReceptionStatusSeverity } from '../../../../../enums/orders';
import { UserRole } from '../../../../../enums/users';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../hooks/useEndpointGuard';
import usePageTitle from '../../../../../hooks/usePageTitle';
import usePreventWindowClose from '../../../../../hooks/usePreventWindowClose';
import usePrevious from '../../../../../hooks/usePrevious';
import useRouteDialog from '../../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../../hooks/useTableColumns';
import useTableState from '../../../../../hooks/useTableState';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  AssignStatusCollection,
  AssignStatusWithSound,
} from '../../../../../types/api/assignStatus';
import {
  AvailableCouriersCollection,
  AvailableCouriersCollectionQueryParams,
} from '../../../../../types/api/couriers';
import {
  DeleteTransferCourierAssignment,
  TransferCourierAssignmentCollection,
  TransferCourierAssignmentCollectionQueryParams,
  TransferCourierAssignmentReportQueryParams,
  TransferCourierSummaryCollection,
  TransferCourierSummaryCollectionQueryParams,
  UndoTransferCourierAssignedShipmentQueryParams,
  UpdateTransferCourierAssignment,
  UpdateTransferCourierAssignmentRequestPayload,
} from '../../../../../types/api/orders';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { Unpacked } from '../../../../../types/util';
import {
  assignShipmentGuard,
  assignStatusCollectionGuard,
  cancelShipmentAssignmentGuard,
} from '../../../../../utils/constants/auth/hubShipmentDistribution';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../../utils/constants/routePaths';
import { playAudio } from '../../../../../utils/helpers/audio';
import { base64ToAudio } from '../../../../../utils/helpers/audio';
import { downloadFile, getFileName } from '../../../../../utils/helpers/files';
import { sequential } from '../../../../../utils/helpers/functions';
import { queryString } from '../../../../../utils/helpers/http';
import { tryInt } from '../../../../../utils/helpers/parse';
import {
  errorToast,
  successToast,
  warnToast,
} from '../../../../../utils/helpers/primereact';
import Table from '../../../../DataTable/Table/Table';
import Flex from '../../../../layout/flex/Flex';
import MainContent from '../../../../layout/flex/MainContent';
import DetailsSection from '../../../Components/DetailsSection/DetailsSection';
import HeaderPages from '../../../Components/HeaderPages/HeaderPages';
import ViewActiveOrderDialog from '../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../Orders/Orders.functions';
import CommonStateContext from '../../_AssignmentAndReception/CommonStateContext';
import CreateWorkOrderDialog from '../../_AssignmentAndReception/Dialogs/Create/WorkOrder/CreateWorkOrderDialog';
import Barcode from '../../_AssignmentAndReception/Sidebar/Barcode';
import Filters from '../../_AssignmentAndReception/Sidebar/Filters';
import {
  CandidatesFilter,
  WarehousePage,
} from '../../_AssignmentAndReception/types';
import useCommonState from '../../_AssignmentAndReception/useCommonState';
import {
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './HubShipmentDistribution.functions';
import Stats from './Sidebar/Stats';

function HubShipmentDistribution(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Warehouse Shipment Distribution'));

  // Prevent entire page being closed unintentionally.
  usePreventWindowClose();

  const { toastRef, bottomRightToastRef } = useContext(ToastContext);

  const assignGuard = useEndpointGuard(assignShipmentGuard);
  const cancelGuard = useEndpointGuard(cancelShipmentAssignmentGuard);
  const canLoadTransferAssignStatusesGuard = useEndpointGuard(
    assignStatusCollectionGuard
  );

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const state = useCommonState({
    isDestinationHubFilterShown: true,
    noCouriersAvailableMessage: t(
      'No couriers are available for the selected warehouse'
    ),
    page: WarehousePage.HubShipmentDistribution,
  });
  const {
    courierFilter,
    candidatesFilter,
    debouncedSerialNumberFilter,
    isCreateWorkOrderDialogShown,
    handleCreateWorkOrderDialogSuccess,
    handleCreateWorkOrderDialogHide,
    isDateValid,
    courierWorkOrderId,
    hasCourierWorkOrder,
    destinationHubFilter,
    availableHubsCollectionRequest,
    rowClassName,
  } = state;

  const {
    page,
    limit,
    tableRef,
    sortField,
    sortOrder,
    selection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelection,
  } =
    useTableState<Unpacked<TransferCourierAssignmentCollection>>(
      tableStorageKey
    );

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'warehouse_transferShipmentAssignment',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties(t)
    );

  const { data, error, isLoading, reload } =
    useAxiosHook<TransferCourierAssignmentCollection>(
      '/orders/transfer/assignments' +
        queryString<TransferCourierAssignmentCollectionQueryParams>({
          work_order_id: courierWorkOrderId ?? '',
          hub_to_id: destinationHubFilter ?? '',
          show_candidates:
            isDateValid && candidatesFilter === CandidatesFilter.Hidden ? 0 : 1,
          sort_is_candidate:
            candidatesFilter === CandidatesFilter.Last ? 'asc' : 'desc',
          serial_number: debouncedSerialNumberFilter,
          page: page,
          limit: limit,
        }),
      { skipWhen: !hasCourierWorkOrder || destinationHubFilter === null }
    );

  const availableCouriersCollectionRequest =
    useAxiosHook<AvailableCouriersCollection>(
      '/couriers/available' +
        queryString<AvailableCouriersCollectionQueryParams>({
          is_assigned_to_region: IntBool.False,
          user_role_id: UserRole.TransferCourier,
          hub_to_id: destinationHubFilter ?? '',
        }),
      {
        skipWhen:
          !availableHubsCollectionRequest.data?.length || !destinationHubFilter,
      }
    );

  const statsRequest = useAxiosHook<TransferCourierSummaryCollection>(
    '/orders/transfer/summary' +
      queryString<TransferCourierSummaryCollectionQueryParams>({
        work_order_id: courierWorkOrderId ?? '',
      }),
    { skipWhen: !hasCourierWorkOrder }
  );

  useToastMessage(undefined, statsRequest.error, {
    error: {
      summary: t('An error occured while reading stats data.'),
    },
  });

  const {
    data: assignOrderData,
    error: assignOrderError,
    reload: assignOrderReload,
  } = useAxiosHook<UpdateTransferCourierAssignment>();

  const handleValidBarcodeScan = useCallback(
    (barcode: string) => {
      if (!barcode.length) {
        return;
      }

      const data: UpdateTransferCourierAssignmentRequestPayload = {
        work_order_id: courierWorkOrderId!,
        serial_number: barcode,
        hub_to_id: destinationHubFilter!,
      };

      assignOrderReload({
        url: '/orders/transfer/assignments',
        method: 'PUT',
        data,
      });
    },
    [assignOrderReload, destinationHubFilter, courierWorkOrderId]
  );

  const prevAssignOrderData = usePrevious(assignOrderData);

  useToastMessage(undefined, assignOrderError, {
    error: {
      summary: t('An error occured while trying to assign shipment.'),
    },
  });

  // fetch transfer assign statuses from API and convert sound string to Audio
  const { data: transferAssignStatuses } = useAxiosHook<AssignStatusCollection>(
    '/transfer-assign-statuses',
    {
      skipWhen: !canLoadTransferAssignStatusesGuard,
    }
  );

  const assignStatuses = useMemo<
    undefined | Record<number, AssignStatusWithSound>
  >(() => {
    if (!transferAssignStatuses) {
      return {} as Record<number, AssignStatusWithSound>;
    }

    return Object.fromEntries(
      transferAssignStatuses.map((r) => [
        r.id,
        {
          id: r.id,
          message: r.message,
          sound: !r.sound ? undefined : base64ToAudio(r.sound),
          severity: r.severity,
        },
      ])
    ) as Record<number, AssignStatusWithSound>;
  }, [transferAssignStatuses]);

  useEffect(() => {
    if (
      !assignOrderData ||
      assignOrderData === prevAssignOrderData ||
      !assignStatuses ||
      !transferAssignStatuses
    ) {
      return;
    }

    const defaultWarehouseOrderAssignmentStatus =
      getWarehouseTransferCourierOrderAssignmentStatusConfig(t);

    const assignStatusId = assignOrderData.assignment_status_id;

    const assignStatus: AssignStatusWithSound = (() => {
      let result: AssignStatusWithSound = {
        id: assignStatusId,
        message: '',
        severity: WarehouseAssignmentAndReceptionStatusSeverity.Error,
        sound: undefined,
      };

      if (defaultWarehouseOrderAssignmentStatus[assignStatusId]) {
        result.message =
          defaultWarehouseOrderAssignmentStatus[assignStatusId].message;
        result.severity =
          defaultWarehouseOrderAssignmentStatus[assignStatusId].severity;
      }

      if (assignStatuses && assignStatuses[assignStatusId]) {
        if (assignStatuses[assignStatusId].sound) {
          result.sound = assignStatuses[assignStatusId].sound;
        }
        if (assignStatuses[assignStatusId].severity) {
          result.severity = assignStatuses[assignStatusId].severity;
        }
      }

      if (
        defaultWarehouseOrderAssignmentStatus[assignStatusId] &&
        !result.sound
      ) {
        result.sound = getAssignmentAndReceptionStatusSeveritySound(
          defaultWarehouseOrderAssignmentStatus[assignStatusId].severity,
          true
        );
      }

      return result;
    })();

    switch (assignStatus.severity) {
      case WarehouseAssignmentAndReceptionStatusSeverity.Info:
        successToast(toastRef, t('Success'), assignStatus.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Warning:
        warnToast(toastRef, t('Warning'), assignStatus.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Error:
        errorToast(toastRef, t('Error'), assignStatus.message);
        break;
    }

    if (assignStatus.sound) {
      playAudio(assignStatus.sound);
    }

    if (
      assignStatus.severity !==
      WarehouseAssignmentAndReceptionStatusSeverity.Error
    ) {
      reload();
      statsRequest.reload();
    }
  }, [
    assignOrderData,
    transferAssignStatuses,
    prevAssignOrderData,
    reload,
    statsRequest,
    t,
    toastRef,
    assignStatuses,
  ]);

  const { id } = useParams<EntityIdRouteParams>();

  const {
    show: showViewOrderDialog,
    hide: hideViewOrderDialog,
    isVisible: isViewOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.HubShipmentDistributions,
    constructIdRoute(
      RoutePaths.ViewHubShipmentDistribution,
      id ?? selection?.order_id
    )
  );

  const { data: orderData, isLoading: isOrderDataLoading } =
    useAxiosHook<SingleOrder>(
      {
        url: `/orders/${id}`,
      },
      {
        skipWhen: !isViewOrderDialogVisible,
      }
    );

  const {
    data: cancelShipmentData,
    error: cancelShipmentError,
    reload: cancelShipmentReload,
  } = useAxiosHook<DeleteTransferCourierAssignment>();

  useToastMessage(cancelShipmentData, cancelShipmentError, {
    success: {
      summary: t('Successfully cancelled assignment for shipment {{id}}.', {
        id: cancelShipmentData?.serial_number ?? '',
      }),
      callback: () => sequential(reload, statsRequest.reload),
    },
    error: {
      summary: t(
        'An error occured while trying to cancel the shipment assignment.'
      ),
    },
  });

  const assignShipment = useCallback(() => {
    if (!selection?.serial_number) {
      return;
    }

    handleValidBarcodeScan(selection.serial_number);
  }, [handleValidBarcodeScan, selection?.serial_number]);

  function handlePrintCourierReceiptBtnClick() {
    if (!hasCourierWorkOrder) {
      return;
    }

    if (!destinationHubFilter) {
      return;
    }

    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/orders/transfer/assignments/pdf` +
        queryString<TransferCourierAssignmentReportQueryParams>({
          work_order_id: courierWorkOrderId!,
          hub_to_id: destinationHubFilter,
        }),
      getFileName(
        t('WarehouseShipmentDistribution'),
        String(courierWorkOrderId),
        true
      ),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  const contextMenuModel = useMemo(() => {
    function handleCancelCMClick() {
      if (!selection) {
        return;
      }

      cancelShipmentReload({
        url:
          `/orders/transfer/assignments/${selection.order_id}` +
          queryString<UndoTransferCourierAssignedShipmentQueryParams>({
            work_order_id: courierWorkOrderId!,
            transfer_work_order_id: selection.transfer_work_order_id,
          }),
        method: 'DELETE',
      });
    }

    const isAssignShown =
      isDateValid && assignGuard && !!tryInt(selection?.is_candidate);

    const isCancelShown =
      isDateValid &&
      cancelGuard &&
      !!selection?.is_candidate &&
      !parseInt(selection.is_candidate);

    return generateContextMenu({
      t,
      onViewClick: showViewOrderDialog,
      isAssignShown,
      onAssignClick: assignShipment,
      isCancelShown,
      onCancelClick: handleCancelCMClick,
    });
  }, [
    assignGuard,
    assignShipment,
    cancelGuard,
    cancelShipmentReload,
    courierWorkOrderId,
    isDateValid,
    selection,
    showViewOrderDialog,
    t,
  ]);

  return (
    <CommonStateContext.Provider value={state}>
      <div className="page page-assigment">
        <HeaderPages
          title={t('Assignment To Warehouse')}
          subtitle={t('Distribute orders to a warehouse')}
          icon={faWarehouse}
        >
          <Button
            label={t('Print load Courier Receipt')}
            icon="fas fa-print"
            className="main-btn"
            disabled={!hasCourierWorkOrder || !courierFilter}
            onClick={handlePrintCourierReceiptBtnClick}
            data-cy="create-btn"
          />
        </HeaderPages>

        <ViewActiveOrderDialog
          data={orderData}
          visible={isViewOrderDialogVisible}
          onHide={hideViewOrderDialog}
          isLoading={isOrderDataLoading}
        />

        <CreateWorkOrderDialog
          visible={isCreateWorkOrderDialogShown}
          onHide={handleCreateWorkOrderDialogHide}
          onSuccess={handleCreateWorkOrderDialogSuccess}
          couriers={availableCouriersCollectionRequest.data}
          courierFilter={courierFilter}
        />

        <Flex direction="column">
          <Filters
            availableCouriersRequest={availableCouriersCollectionRequest}
          />
          <DetailsSection>
            {assignGuard && (
              <Barcode onValidBarcodeScan={handleValidBarcodeScan} />
            )}

            <Stats request={statsRequest} />
          </DetailsSection>

          <MainContent>
            <Table
              tableRef={tableRef}
              columns={columns}
              data={hasCourierWorkOrder ? data : undefined}
              isLoading={isLoading}
              hasError={!!error}
              filterHeight={360}
              reload={() => sequential(reload, statsRequest.reload)}
              isReloadDisabled={!hasCourierWorkOrder}
              setPage={setPage}
              setLimit={setLimit}
              sortField={sortField}
              rows={limit}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
              setSelection={setSelection}
              sortOrder={sortOrder}
              selection={selection}
              storageString={tableStorageKey}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              columnOptions={columnOptions}
              headerTitle={t('Shipments')}
              onRowDoubleClick={showViewOrderDialog}
              contextMenuModel={contextMenuModel}
              rowClassName={rowClassName}
            />
          </MainContent>
        </Flex>
      </div>
    </CommonStateContext.Provider>
  );
}

export default HubShipmentDistribution;
