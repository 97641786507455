import { clientLookupCollectionGuard } from './_api/clients';
import {
  createLockerResourceGuard,
  deleteLockerResourceGuard,
  editLockerResourceGuard,
  lockerCollectionGuard,
  lockerResourceGuard,
} from './_api/lockers';
import {
  municipalityCollectionGuard,
  placeCollectionGuard,
} from './_api/municipalities';
import {
  placeResourceGuard,
  placesMunicipalityCollectionGuard,
  placesStreetCollectionGuard,
} from './_api/places';

export const readLocker = lockerResourceGuard;

export const createHub = [
  createLockerResourceGuard,
  placeResourceGuard,
  municipalityCollectionGuard,
  placeCollectionGuard,
  placesStreetCollectionGuard,
  clientLookupCollectionGuard,
];

export const editHub = [readLocker, editLockerResourceGuard];

export const deleteHub = [readLocker, deleteLockerResourceGuard];

export const municipalityFilter = municipalityCollectionGuard;
export const placesFilter = placesMunicipalityCollectionGuard;

export const routeGuard = lockerCollectionGuard;
