import './ViewDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import GeoLocation from './GeoLocation';

function ViewDialog({
  data,
  isShown,
  onHide,
}: {
  data: any;
  isShown: boolean;
  onHide: any;
}) {
  const { t } = useTranslation();

  const dialogFooter = (
    <>
      <Button type="button" label={t('Close')} onClick={() => onHide()} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={data?.name}
      footer={dialogFooter}
      dismissableMask
      blockScroll
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      className="lockers-view-dialog"
    >
      <>
        <span className="section-title">{t('General information')}</span>
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td>{t("Warehouse's name")}</td>
              <td>{data?.title}</td>
            </tr>
            <tr>
              <td>{t('Address')}</td>
              <td>
                {`${data?.address?.municipalityName} ${data?.address?.rawName} ${data?.address?.streetName}`}
              </td>
            </tr>

            <tr>
              <td>{t('Department')}</td>
              <td>{data?.department?.name}</td>
            </tr>

            <tr>
              <td>{t('Organization')}</td>
              <td>{data?.department?.organization?.mol}</td>
            </tr>

            <tr>
              <td>{t('Number boxes')}</td>
              <td>{data?.numBoxes}</td>
            </tr>

            <tr>
              <td>{t('Comment')}</td>
              <td>{data?.comment}</td>
            </tr>
          </tbody>
        </table>

        <span className="section-title">{t('Location')}</span>
        <GeoLocation data={data} />
      </>
    </Dialog>
  );
}

export default ViewDialog;
