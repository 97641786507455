import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { SmartPOSTerminals } from '../../../types/api/smartPosTerminals';
import { Unpacked } from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateFormat } from '../../../utils/helpers/formatting';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'smartPOSTerminals_dataTable';

export type Row = Unpacked<SmartPOSTerminals>;

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    statusId: t('Status'),
    username: t('Name'),
    deviceName: t('Device'),
    terminalId: t('Terminal ID'),
    createdAt: t('Created at'),
    modifiedAt: t('Modified at'),
    activationCode: t('Activation Code'),
    pin: t('Pin'),
  };
}

function getStatus(t: TFunction, status: number) {
  let color;
  let tooltip;

  if (status === 1) {
    color = 'var(--green-500)';
    tooltip = t('Active');
  } else if (status === 2) {
    color = 'var(--yellow-500)';
    tooltip = t('Inactive');
  } else if (status === 3) {
    color = 'var(--red-500)';
    tooltip = t('Blocked');
  } else {
    color = 'var(--gray-500)';
    tooltip = t('Unknown');
  }

  return (
    <span data-tip={tooltip} data-for="tooltip-right">
      <FontAwesomeIcon icon={faCircle} style={{ color }} />
    </span>
  );
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>,
  viewGuard: boolean,
  editGuard: boolean,
  deleteGuard: boolean
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          justifyContent: 'center',
        },
      };

    case 'statusId':
      return {
        body: (row: Row) => {
          return getStatus(t, row[column]);
        },
        style: { width: 80, maxWidth: 110 },
        bodyStyle: {
          width: 80,
          maxWidth: 110,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'createdAt':
    case 'modifiedAt':
      return {
        body: (row: Row) =>
          row[column] ? (
            <span data-tip={dateFormat(row[column]!)} data-for="tooltip-right">
              {dayjs(row[column]!).fromNow()}
            </span>
          ) : (
            emptyCell
          ),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'actions':
      return {
        body: (row: Row) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View Details'),
                  icon: 'fas fa-search',
                  disabled: !viewGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Edit'),
                  icon: 'fas fa-edit',
                  disabled: !editGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Delete terminal'),
                  icon: 'fas fa-trash',
                  disabled: !deleteGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('delete-order');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
  }
}
