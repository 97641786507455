import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import { Column } from 'primereact/column';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../hooks/useAxios';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { LockerCollection } from '../../../types/api/lockers';
import { Unpacked } from '../../../types/util';
import * as lockersGuards from '../../../utils/constants/auth/lockers';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import ViewDialog from './Dialog/ViewDialog';
import {
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './LockersList.functions';
import useTableFilters from './useTableFilters';

function LockersList() {
  const { t } = useTranslation();
  const [isViewDialogShown, setIsViewDialogShown] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [action, setAction] = useState<string>('');
  usePageTitle(t('Lockers List'));

  const readGuard = useEndpointGuard(lockersGuards.readLocker);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Unpacked<LockerCollection>>(tableStorageKey);

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'employees',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          readGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          readGuard
        )}
      />,
    ],
    [columns, t, setContextMenuSelection, readGuard]
  );

  const { data, isLoading, reload, error } = useAxiosHook<LockerCollection>(
    '/lockers/list' + queryString(httpQueryObject(httpFiltersObj))
  );

  const { reload: reloadData, isLoading: isFormSubmissionRequestLoading } =
    useAxios();

  // Context menu functions
  const handleCMViewClick = useCallback(() => {
    reloadData({
      url: `/lockers/${contextMenuSelection?.id}`,
      successCallback: (data: any) => {
        setDialogData(data);
        setIsViewDialogShown(true);
      },
    });
  }, [contextMenuSelection, reloadData]);

  function handleViewDialogHide() {
    setIsViewDialogShown(false);
    setDialogData({});
  }

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'view-details') {
        handleCMViewClick();
      }

      setAction('');
    }
  }, [action, contextMenuSelection, setAction, handleCMViewClick]);

  return (
    <div className="page lockers-page">
      <HeaderPages
        title={t('Lockers List')}
        subtitle={t('View and manage lockers')}
        icon={faBoxes}
      />

      <ViewDialog
        isShown={isViewDialogShown}
        data={dialogData}
        onHide={handleViewDialogHide}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={220}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={220}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}
export default LockersList;
