import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { Numeric } from '../../../../../../types/general';
import { SingleOrder } from '../../../Orders.functions';
import styles from '../StatusTrackingDialog.module.scss';

type Props = {
  orderId: Numeric | undefined;
  buttonLabel: string;
  setShowGoBackBtn: Dispatch<SetStateAction<boolean>>;
};

function RelatedOrderButton({
  buttonLabel,
  orderId,
  setShowGoBackBtn,
}: Props): JSX.Element | null {
  const history = useHistory();
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const { data: orderData } = useAxiosHook<SingleOrder>(`/orders/${orderId}`, {
    skipWhen: !orderId,
  });

  const relatedShipmentInfoClassName = classNames('p-d-flex', {
    'p-flex-row  p-mb-2 p-ai-center': !isOnMobile,
    'p-flex-column  p-mb-3': isOnMobile,
  });

  if (!orderData) {
    return null;
  }

  return (
    <div className={relatedShipmentInfoClassName}>
      <span className={styles['info-header']}>{buttonLabel}</span>
      <div>
        <Button
          label={orderData.seriski_broj ?? undefined}
          onClick={() => {
            history.push(`/orders/active/${orderData?.seriski_broj}/view`);

            setShowGoBackBtn(true);
          }}
          className="p-button-link"
        />
      </div>
    </div>
  );
}

export default RelatedOrderButton;
