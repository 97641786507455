import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  useFormikContext,
} from 'formik';
import _ from 'lodash';
import { Checkbox } from 'primereact/checkbox';
import { ChangeEvent, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-lodash-debounce';

import useAxiosHook from '../../../../../../hooks/useAxiosHook';
import { EmployeeCollection } from '../../../../../../types/api/employees';
import { LabelValue } from '../../../../../../types/options';
import { debounceTimeout } from '../../../../../../utils/constants/misc';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import FieldWithErrors from '../../../../../Forms/FieldWithErrors/FieldWithErrors';
import { Courier, Region } from '../../../Regions.functions';
import {
  CourierOption,
  FormValues,
  assignments,
} from './AddEditCourierDialog.functions';

type Props = {
  selectedRegion: Region | undefined;
  isEditDialog: boolean;
};

function DialogForm({ isEditDialog, selectedRegion }: Props): JSX.Element {
  const { t } = useTranslation();

  const [courierFilter, setCourierFilter] = useState<string>('');

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const debouncedCourier = useDebounce<string>(courierFilter, debounceTimeout);

  const { data: employeeData, isLoading: isEmployeeListLoading } =
    useAxiosHook<EmployeeCollection>(
      `/employees?roles[]=3&active=1&name=${debouncedCourier}&limit=20`,
      {
        skipWhen: isEditDialog || !!values.courier,
      }
    );

  const allCourierOptions = useMemo<LabelValue<CourierOption>[]>(() => {
    if (isEditDialog) {
      return [];
    }

    function isCourierInsideSelectedRegion(e: CourierOption): boolean {
      return !!selectedRegion?.couriers.find((c) => c.id === e.id);
    }

    return (
      employeeData?.data
        ?.filter((e) => !isCourierInsideSelectedRegion(e))
        .map((e) => ({
          label: `${e.ime} ${e.prezime}`,
          value: e,
        })) ?? []
    );
  }, [employeeData, isEditDialog, selectedRegion]);

  const selectedCourier = useMemo<Courier | undefined>(() => {
    if (isEditDialog || typeof values.courier === 'string') {
      return undefined;
    }

    return selectedRegion?.couriers.find(
      (c) => c.id === (values.courier as CourierOption).id
    );
  }, [isEditDialog, selectedRegion, values.courier]);

  const handleCourierSelectionChange = useCallback(
    (newValue: CourierOption | null): void => {
      setFieldValue('courier', newValue ?? '');
    },
    [setFieldValue]
  );

  return (
    <div className="info">
      {isEditDialog ? (
        <span>
          {selectedCourier?.first_name} {selectedCourier?.last_name}
        </span>
      ) : (
        <div className="info-item">
          <h4>{t('Courier')}</h4>
          <div className="p-fluid">
            <FieldWithErrors name="courier" label={false}>
              <Field
                name="courier"
                id="courier"
                as={AutoCompleteInput}
                filterValue={courierFilter}
                onFilterChange={setCourierFilter}
                onSelectionChange={handleCourierSelectionChange}
                options={allCourierOptions}
                isLoading={isEmployeeListLoading}
                filterDataCy="courier"
                optionsClassName="data-cy-courier-options"
              />
            </FieldWithErrors>
          </div>
        </div>
      )}

      <div className="info-item">
        <h4>{t('Region')}</h4>
        <span>{selectedRegion?.name}</span>
      </div>

      <div className="info-item">
        <h4>{t('Assignments')}</h4>

        <FieldWithErrors
          name="assignments"
          label={false}
          childrenWrapperClassName="assignments-wrapper data-cy-assignments"
        >
          <span />
          <span>{t('Pickup')}</span>
          <span>{t('Deliver')}</span>

          <FieldArray
            name="assignments"
            render={(arrayHelpers: FieldArrayRenderProps) => {
              return assignments().map((__, index) => (
                <Fragment key={index}>
                  {index === 0 && <span>{t('Packages')}</span>}
                  {index === 2 && <span>{t('Letters')}</span>}
                  {index === 4 && <span>{t('Personal Files')}</span>}
                  {index === 6 && <span>{t('Oversize Package')}</span>}

                  <Field
                    key={index}
                    name={`values.assignments[${index}].isChecked)`}
                    as={Checkbox}
                    checked={values.assignments[index].isChecked}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                      arrayHelpers.replace(index, {
                        isChecked: e.target.checked,
                        ..._.pick(
                          values.assignments[index],
                          'product_id',
                          'shipment_type_id'
                        ),
                      });
                    }}
                  />
                </Fragment>
              ));
            }}
          />
        </FieldWithErrors>
      </div>
    </div>
  );
}

export default DialogForm;
