import dayjs from 'dayjs';

import {
  formatMobileNumber,
  formatPhoneNumber,
  getPhoneorMobileNumberRegion,
} from '../../../../utils/helpers/phoneNumbers';

export const basicInfoTabFields = {
  aktiven: true,
  ime: '',
  prezime: '',
  adresa: '',
  mesto_id: null,
  telefon: '',
  mobilen: '',
  komentar: '',

  // Helper fields for the UI
  municipality: '',
  municipality_id: null,
  place: '',

  // Add new only
  username: '',
  datum_raganje: '',
  embg: '',
  password: '',
};

export const rolesDataTabFields = {
  roles: [],
};

export const hubsTabFields = {
  hub_id: '',
  hub_ime: '',
  hubs: [],
};

export const vehiclesTabFields = {
  vozilo_id: null,
};

const fields = {
  ...basicInfoTabFields,
  ...rolesDataTabFields,
  ...hubsTabFields,
  ...vehiclesTabFields,
};

export function getInitialValues(data = {}) {
  let initialValues = {};

  for (let f in fields) {
    // Map API returned vehicle to vozilo_id
    if (f === 'vozilo_id') {
      initialValues['vozilo_id'] = Number(data['vehicle']) || fields[f];
      continue;
    }
    initialValues[f] = data[f] || fields[f];
  }

  if (Object.keys(data).length) {
    initialValues.aktiven = !!parseInt(data.aktiven);
    // API returns 'ulogi', but accepts 'roles'
    initialValues.roles = data.ulogi?.map((r) => parseInt(r.id));
    initialValues.hubs = data.hubs?.map((r) => parseInt(r.id));
  }

  initialValues.mobilen_region = getPhoneorMobileNumberRegion(
    initialValues.mobilen
  );

  initialValues.telefon_region = getPhoneorMobileNumberRegion(
    initialValues.telefon
  );

  return initialValues;
}

export function toApiData(values, isEditDialog) {
  let apiData = {
    ...values,
  };

  // Checkboxes
  apiData.aktiven = apiData.aktiven ? 1 : 0;

  // Mobile and phone numbers
  if (apiData.telefon) {
    apiData.telefon = formatPhoneNumber(
      apiData.telefon,
      apiData.telefon_region
    );
  }

  if (apiData.mobilen) {
    apiData.mobilen = formatMobileNumber(
      apiData.mobilen,
      apiData.mobilen_region
    );
  }

  // Delete unnecessary data
  delete apiData.place;
  delete apiData.municipality;
  delete apiData.municipality_id;
  delete apiData.hub_ime;
  delete apiData.telefon_region;
  delete apiData.mobilen_region;
  // Hubs and roles are sent to different endpoints ; hubs always, roles only on edit
  delete apiData.hubs;
  delete apiData.vozilo_id;

  if (apiData.datum_raganje) {
    apiData.datum_raganje = dayjs(apiData.datum_raganje).format(
      'YYYY-MM-DD HH:mm:ss'
    );
  }

  if (isEditDialog) {
    delete apiData.roles;
    delete apiData.username;
    delete apiData.datum_raganje;
    delete apiData.embg;
    delete apiData.password;
    delete apiData.vozilo_id;
  }

  return apiData;
}
