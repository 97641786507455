import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getAssignmentAndReceptionStatusSeveritySound,
  getWarehouseTransferCourierOrderReceptionStatusConfig,
} from '../../../../../configs/orders';
import ToastContext from '../../../../../context/ToastContext';
import { IntBool } from '../../../../../enums/booleans';
import { WarehouseAssignmentAndReceptionStatusSeverity } from '../../../../../enums/orders';
import { UserRole } from '../../../../../enums/users';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../hooks/useEndpointGuard';
import usePageTitle from '../../../../../hooks/usePageTitle';
import usePreventWindowClose from '../../../../../hooks/usePreventWindowClose';
import usePrevious from '../../../../../hooks/usePrevious';
import useRouteDialog from '../../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../../hooks/useTableColumns';
import useTableState from '../../../../../hooks/useTableState';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  AvailableCouriersCollection,
  AvailableCouriersCollectionQueryParams,
} from '../../../../../types/api/couriers';
import {
  DeleteTransferCourierReception,
  TransferCourierReceptionCollection,
  TransferCourierReceptionCollectionQueryParams,
  TransferCourierSummaryCollection,
  TransferCourierSummaryCollectionQueryParams,
  UndoTransferCourierReceivedShipmentQueryParams,
  UpdateTransferCourierReception,
  UpdateTransferCourierReceptionRequestPayload,
} from '../../../../../types/api/orders';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { Unpacked } from '../../../../../types/util';
import {
  cancelShipmentReceptionGuard,
  receiveShipmentGuard,
} from '../../../../../utils/constants/auth/hubShipmentReception';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../../utils/constants/routePaths';
import { base64ToAudio, chainAudio } from '../../../../../utils/helpers/audio';
import { sequential } from '../../../../../utils/helpers/functions';
import { queryString } from '../../../../../utils/helpers/http';
import { tryInt } from '../../../../../utils/helpers/parse';
import {
  errorToast,
  successToast,
  warnToast,
} from '../../../../../utils/helpers/primereact';
import Table from '../../../../DataTable/Table/Table';
import Flex from '../../../../layout/flex/Flex';
import MainContent from '../../../../layout/flex/MainContent';
import DetailsSection from '../../../Components/DetailsSection/DetailsSection';
import HeaderPages from '../../../Components/HeaderPages/HeaderPages';
import ViewActiveOrderDialog from '../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../Orders/Orders.functions';
import CommonStateContext from '../../_AssignmentAndReception/CommonStateContext';
import CreateWorkOrderDialog from '../../_AssignmentAndReception/Dialogs/Create/WorkOrder/CreateWorkOrderDialog';
import Barcode from '../../_AssignmentAndReception/Sidebar/Barcode';
import Filters from '../../_AssignmentAndReception/Sidebar/Filters';
import {
  CandidatesFilter,
  WarehousePage,
} from '../../_AssignmentAndReception/types';
import useCommonState from '../../_AssignmentAndReception/useCommonState';
import {
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './HubShipmentReception.functions';
import Stats from './Sidebar/Stats';

function HubShipmentReception(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Warehouse Shipment Reception'));

  // Prevent entire page being closed unintentionally.
  usePreventWindowClose();

  const { toastRef } = useContext(ToastContext);

  const receiveGuard = useEndpointGuard(receiveShipmentGuard);
  const cancelGuard = useEndpointGuard(cancelShipmentReceptionGuard);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const state = useCommonState({ page: WarehousePage.HubShipmentReception });
  const {
    candidatesFilter,
    debouncedSerialNumberFilter,
    isCreateWorkOrderDialogShown,
    handleCreateWorkOrderDialogSuccess,
    handleCreateWorkOrderDialogHide,
    isDateValid,
    courierWorkOrderId,
    hasCourierWorkOrder,
    courierFilter,
    regionSounds,
    isRegionSoundsLoading,
    rowClassName,
  } = state;

  const {
    page,
    limit,
    tableRef,
    sortField,
    sortOrder,
    selection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelection,
  } =
    useTableState<Unpacked<TransferCourierReceptionCollection>>(
      tableStorageKey
    );

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'warehouse_hubShipmentReception',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties(t)
    );

  const { data, error, isLoading, reload } =
    useAxiosHook<TransferCourierReceptionCollection>(
      '/orders/transfer/reception' +
        queryString<TransferCourierReceptionCollectionQueryParams>({
          work_order_id: courierWorkOrderId ?? '',
          show_candidates:
            isDateValid && candidatesFilter === CandidatesFilter.Hidden ? 0 : 1,
          sort_is_candidate:
            candidatesFilter === CandidatesFilter.Last ? 'asc' : 'desc',
          serial_number: debouncedSerialNumberFilter,
          page: page,
          limit: limit,
        }),
      { skipWhen: !hasCourierWorkOrder }
    );

  const couriersCollectionRequest = useAxiosHook<AvailableCouriersCollection>(
    '/couriers/available' +
      queryString<AvailableCouriersCollectionQueryParams>({
        is_assigned_to_region: IntBool.False,
        user_role_id: UserRole.TransferCourier,
      })
  );

  const statsRequest = useAxiosHook<TransferCourierSummaryCollection>(
    '/orders/transfer/summary' +
      queryString<TransferCourierSummaryCollectionQueryParams>({
        work_order_id: courierWorkOrderId ?? '',
      }),
    { skipWhen: !hasCourierWorkOrder }
  );

  useToastMessage(undefined, statsRequest.error, {
    error: {
      summary: t('An error occured while reading stats data.'),
    },
  });

  const {
    data: receiveOrderData,
    error: receiveOrderError,
    reload: receiveOrderReload,
  } = useAxiosHook<UpdateTransferCourierReception>();

  const handleValidBarcodeScan = useCallback(
    (barcode: string) => {
      if (!barcode.length) {
        return;
      }

      const data: UpdateTransferCourierReceptionRequestPayload = {
        work_order_id: courierWorkOrderId!,
        serial_number: barcode,
      };

      receiveOrderReload({
        url: '/orders/transfer/reception',
        method: 'PUT',
        data,
      });
    },
    [receiveOrderReload, courierWorkOrderId]
  );

  const prevReceiveOrderData = usePrevious(receiveOrderData);

  useToastMessage(undefined, receiveOrderError, {
    error: {
      summary: t('An error occured while trying to receive shipment.'),
    },
  });

  useEffect(() => {
    if (!receiveOrderData || receiveOrderData === prevReceiveOrderData) {
      return;
    }

    const warehouseOrderReceptionStatusConfig =
      getWarehouseTransferCourierOrderReceptionStatusConfig(t);

    const status = receiveOrderData.assignment_status_id;

    const config = warehouseOrderReceptionStatusConfig[status];

    const recipientRegionId = receiveOrderData.recipient_region_id;

    switch (config.severity) {
      case WarehouseAssignmentAndReceptionStatusSeverity.Info:
        successToast(toastRef, t('Success'), config.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Warning:
        warnToast(toastRef, t('Warning'), config.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Error:
        errorToast(toastRef, t('Error'), config.message);
        break;
    }

    const receptionStatusSound = getAssignmentAndReceptionStatusSeveritySound(
      config.severity
    );

    // Skip region sounds for unregistered shipments
    const regionSound =
      recipientRegionId && regionSounds[recipientRegionId]
        ? base64ToAudio(regionSounds[recipientRegionId])
        : undefined;

    const sounds: HTMLAudioElement[] = [
      receptionStatusSound,
      regionSound,
    ].filter((s): s is HTMLAudioElement => !!s);

    chainAudio(sounds);

    if (
      config.severity !== WarehouseAssignmentAndReceptionStatusSeverity.Error
    ) {
      reload();
      statsRequest.reload();
    }
  }, [
    prevReceiveOrderData,
    receiveOrderData,
    regionSounds,
    reload,
    statsRequest,
    t,
    toastRef,
  ]);

  const { id } = useParams<EntityIdRouteParams>();

  const {
    show: showViewOrderDialog,
    hide: hideViewOrderDialog,
    isVisible: isViewOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.HubShipmentReceptions,
    constructIdRoute(
      RoutePaths.ViewHubShipmentReception,
      id ?? selection?.order_id
    )
  );

  const { data: orderData, isLoading: isOrderDataLoading } =
    useAxiosHook<SingleOrder>(
      {
        url: `/orders/${id}`,
      },
      {
        skipWhen: !isViewOrderDialogVisible,
      }
    );

  const {
    data: cancelShipmentData,
    error: cancelShipmentError,
    reload: cancelShipmentReload,
  } = useAxiosHook<DeleteTransferCourierReception>();

  useToastMessage(cancelShipmentData, cancelShipmentError, {
    success: {
      summary: t('Successfully cancelled reception for shipment {{id}}.', {
        id: cancelShipmentData?.serial_number ?? '',
      }),
      callback: () => sequential(reload, statsRequest.reload),
    },
    error: {
      summary: t(
        'An error occured while trying to cancel the shipment reception.'
      ),
    },
  });

  const receiveShipment = useCallback(() => {
    if (!selection?.serial_number) {
      return;
    }

    handleValidBarcodeScan(selection.serial_number);
  }, [handleValidBarcodeScan, selection?.serial_number]);

  const contextMenuModel = useMemo(() => {
    function handleCancelCMClick() {
      if (!selection) {
        return;
      }

      cancelShipmentReload({
        url:
          `/orders/transfer/reception/${selection?.order_id}` +
          queryString<UndoTransferCourierReceivedShipmentQueryParams>({
            work_order_id: courierWorkOrderId!,
            transfer_work_order_id: selection?.transfer_work_order_id ?? '',
          }),
        method: 'DELETE',
      });
    }

    const isReceiveShown =
      isDateValid && receiveGuard && !!tryInt(selection?.is_candidate);

    const isCancelShown =
      isDateValid &&
      cancelGuard &&
      !!selection?.is_candidate &&
      !parseInt(selection.is_candidate);

    return generateContextMenu({
      t,
      onViewClick: showViewOrderDialog,
      isReceiveShown,
      onReceiveClick: receiveShipment,
      isCancelShown,
      onCancelClick: handleCancelCMClick,
    });
  }, [
    cancelGuard,
    cancelShipmentReload,
    courierWorkOrderId,
    isDateValid,
    receiveGuard,
    receiveShipment,
    selection,
    showViewOrderDialog,
    t,
  ]);

  return (
    <CommonStateContext.Provider value={state}>
      <div className="page page-recepttion">
        <HeaderPages
          title={t('Reception From Warehouse')}
          subtitle={t('Receive orders from a warehouse')}
          icon={faWarehouse}
        />

        <ViewActiveOrderDialog
          data={orderData}
          visible={isViewOrderDialogVisible}
          onHide={hideViewOrderDialog}
          isLoading={isOrderDataLoading}
        />

        <CreateWorkOrderDialog
          visible={isCreateWorkOrderDialogShown}
          onHide={handleCreateWorkOrderDialogHide}
          onSuccess={handleCreateWorkOrderDialogSuccess}
          couriers={couriersCollectionRequest.data}
          courierFilter={courierFilter}
        />

        <Flex direction="column">
          <Filters availableCouriersRequest={couriersCollectionRequest} />
          <DetailsSection>
            {receiveGuard && (
              <Barcode
                onValidBarcodeScan={handleValidBarcodeScan}
                disabled={isRegionSoundsLoading}
              />
            )}

            <Stats request={statsRequest} />
          </DetailsSection>

          <MainContent>
            <Table
              tableRef={tableRef}
              columns={columns}
              data={hasCourierWorkOrder ? data : undefined}
              isLoading={isLoading}
              hasError={!!error}
              filterHeight={360}
              reload={() => sequential(reload, statsRequest.reload)}
              isReloadDisabled={!hasCourierWorkOrder}
              setPage={setPage}
              setLimit={setLimit}
              sortField={sortField}
              rows={limit}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
              setSelection={setSelection}
              sortOrder={sortOrder}
              selection={selection}
              storageString={tableStorageKey}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              columnOptions={columnOptions}
              headerTitle={t('Shipments')}
              onRowDoubleClick={showViewOrderDialog}
              contextMenuModel={contextMenuModel}
              rowClassName={rowClassName}
            />
          </MainContent>
        </Flex>
      </div>
    </CommonStateContext.Provider>
  );
}

export default HubShipmentReception;
