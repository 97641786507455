import './BulkOrders.scss';

import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPaginationAndSummary } from '../../../types/api';
import * as importedListsGuards from '../../../utils/constants/auth/importedLists';
import {
  downloadFile,
  getFileName,
  printAddressOrSticker,
  printPDF,
} from '../../../utils/helpers/files';
import { queryString } from '../../../utils/helpers/http';
import { errorToast } from '../../../utils/helpers/primereact';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import {
  BulkOrder,
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './BulkOrders.functions';
import { Row } from './BulkOrders.types';
import DeleteImportedListDialog from './Dialogs/Delete/ImportedList/DeleteImportedListDialog';
import ImportFile from './Dialogs/ImportFile/ImportFile';
import SendSpecToInvolvedDialog from './Dialogs/SendSpec/SendSpecToInvolvedDialog';
import ViewImportLogDialog from './Dialogs/View/ImportLog/ViewImportLogDialog';
import ViewShipmentsDialog from './Dialogs/View/Shipments/ViewShipmentsDialog';
import WebImportDialog from './Dialogs/WebImport/WebImportDialog';
import useTableFilters from './useTableFilters';

function BulkOrders(): JSX.Element {
  const { t } = useTranslation();
  const readImportLogGuard = useEndpointGuard(
    importedListsGuards.readImportLog
  );
  const readShipmentsGuard = useEndpointGuard(
    importedListsGuards.readShipments
  );
  const sendSpecToInvolvedGuard = useEndpointGuard(
    importedListsGuards.sendSpecToInvolved
  );
  const importFileGuard = useEndpointGuard(importedListsGuards.importFile);
  const importListGuard = useEndpointGuard(importedListsGuards.importList);
  const exportToCsvGuard = useEndpointGuard(importedListsGuards.exportToCsv);
  const deleteImportedListGuard = useEndpointGuard(
    importedListsGuards.deleteImportedList
  );
  usePageTitle(t('Batch Orders'));
  const [isViewLogDialogShown, setIsViewLogDialogShown] = useState(false);
  const [isViewShipmentsDialogShown, setIsViewShipmentsDialogShown] =
    useState(false);
  const [isSendSpecToInvolvedDialogShown, setIsSendSpecToInvolvedDialogShown] =
    useState(false);
  const [isDeleteImportedListDialogShown, setIsDeleteImportedListDialogShown] =
    useState(false);
  const [isImportFromFileDialogShown, setIsImportFromFileDialogShown] =
    useState(false);
  const [isImportFromWebDialogShown, setIsImportFromWebDialogShown] =
    useState(false);
  const [dialogData, setDialogData] = useState<any>(null);
  const [action, setAction] = useState<string>('');
  const { toastRef, bottomRightToastRef } = useContext(ToastContext);
  const [caller, setCaller] =
    useState<'group-actions' | 'context-menu'>('context-menu');
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Row>(tableStorageKey);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'bulk_orders',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          setCaller,
          exportToCsvGuard,
          readImportLogGuard,
          readShipmentsGuard,
          sendSpecToInvolvedGuard,
          deleteImportedListGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          setCaller,
          exportToCsvGuard,
          readImportLogGuard,
          readShipmentsGuard,
          sendSpecToInvolvedGuard,
          deleteImportedListGuard
        )}
      />,
    ],
    [
      columns,
      t,
      setContextMenuSelection,
      setAction,
      setCaller,
      exportToCsvGuard,
      readImportLogGuard,
      readShipmentsGuard,
      sendSpecToInvolvedGuard,
      deleteImportedListGuard,
    ]
  );

  const canLoadData =
    httpFiltersObj.created_date_from && httpFiltersObj.created_date_to;

  const { data, isLoading, reload, error } = useAxiosHook<
    WithPaginationAndSummary<BulkOrder[], { total_orders: string }>
  >('/importlists' + queryString(httpFiltersObj), {
    skipWhen: !canLoadData,
  });

  const dataWithNumericParcelsCount = useMemo(
    () =>
      data
        ? {
            ...data,
            data: data.data.map((r) => ({
              ...r,
              parcels_count: r.parcels_count,
            })),
          }
        : data,
    [data]
  );

  const { reload: reloadData, isLoading: isLoadingData } = useAxios();

  useEffect(() => {
    if (!readImportLogGuard) {
      setIsViewLogDialogShown(false);
    }
  }, [readImportLogGuard]);

  useEffect(() => {
    if (!readShipmentsGuard) {
      setIsViewShipmentsDialogShown(false);
    }
  }, [readShipmentsGuard]);

  useEffect(() => {
    if (!sendSpecToInvolvedGuard) {
      setIsSendSpecToInvolvedDialogShown(false);
    }
  }, [sendSpecToInvolvedGuard]);

  useEffect(() => {
    if (!importFileGuard) {
      setIsImportFromFileDialogShown(false);
    }
  }, [importFileGuard]);

  useEffect(() => {
    if (!importListGuard) {
      setIsImportFromWebDialogShown(false);
    }
  }, [importListGuard]);

  useEffect(() => {
    if (!deleteImportedListGuard) {
      setIsDeleteImportedListDialogShown(false);
    }
  }, [deleteImportedListGuard]);

  const printAddressSticker = useCallback(
    async (typePrint: 'AddressBook' | 'Sticker') => {
      const queryParams = {
        list_id: contextMenuSelection ? contextMenuSelection.id : null,
      };

      printAddressOrSticker(
        queryParams,
        typePrint,
        contextMenuSelection?.client_id,
        bottomRightToastRef
      );
    },
    [bottomRightToastRef, contextMenuSelection]
  );

  const reloadPickupSpec = useCallback(() => {
    printPDF(
      `${process.env.REACT_APP_REPORT_URL}/specification/pdf?list_id=${contextMenuSelection?.id}`,
      bottomRightToastRef
    ).then((data: any) => {
      window.open(data, '_blank')?.print();
      setTimeout(() => {
        window.close();
      }, 1000);
    });
  }, [bottomRightToastRef, contextMenuSelection]);

  const handlePrintSpecification = useCallback(() => {
    reloadPickupSpec();
  }, [reloadPickupSpec]);

  const handleViewImportLogClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    reloadData({
      url: `/importlists/${contextMenuSelection.id}/log`,
      successCallback: (data: any) => {
        debugger;
        setDialogData(data);
        setIsViewLogDialogShown(true);
      },
      errorCallback: () => {
        setIsViewLogDialogShown(false);

        if (toastRef?.current) {
          errorToast(
            toastRef,
            t('Error'),
            t('An error occured while reading data.')
          );
        }
      },
    });
  }, [contextMenuSelection, toastRef, t, reloadData]);

  const handleViewShipmentClick = useCallback(() => {
    setIsViewShipmentsDialogShown(true);
  }, []);

  const handleSendSpecToInvolvedClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    reloadData({
      url: `/orders/batch/parties?import_list_id=${contextMenuSelection.id}`,
      skipWhen: !contextMenuSelection,
      successCallback: (response: any) => {
        setDialogData(response);
        setIsSendSpecToInvolvedDialogShown(true);
      },
      errorCallback: (err: any) => {
        toastRef?.current?.show({
          severity: 'error',
          summary: t('Error'),
          detail: err,
          life: 5000,
        });
      },
    });
  }, [contextMenuSelection, t, toastRef, reloadData]);

  const handleExportToCsv = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadFile(
      `/importlists/${contextMenuSelection?.id}`,
      getFileName(t('BatchOrders'), contextMenuSelection.name),
      FileTypes.CSV,
      bottomRightToastRef,
      (res: { content: string }) => res.content
    );
  }, [contextMenuSelection, bottomRightToastRef, t]);

  const handleDeleteListAndAddresses = useCallback(() => {
    setIsDeleteImportedListDialogShown(true);
  }, []);

  function handleViewLogDialogHide() {
    setIsViewLogDialogShown(false);
  }

  function handleViewShipmentsDialogHide() {
    setIsViewShipmentsDialogShown(false);
    setDialogData({});
  }

  function handleSendSpecToInvolvedDialogHide() {
    setIsSendSpecToInvolvedDialogShown(false);
  }

  function handleDeleteImportedListDialogHide() {
    setIsDeleteImportedListDialogShown(false);
  }

  function handleImportFromFileBtnClick() {
    setIsImportFromFileDialogShown(true);
  }

  function handleImportFromWebBtnClick() {
    setIsImportFromWebDialogShown(true);
  }

  function handleImportFromWebDialogHide() {
    setIsImportFromWebDialogShown(false);
  }

  function handleImportFromFileDialogHide() {
    setIsImportFromFileDialogShown(false);
  }

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-import-log') {
        handleViewImportLogClick();
      }
      if (action === 'view-shipments') {
        handleViewShipmentClick();
      }
      if (action === 'export-to-csv') {
        handleExportToCsv();
      }
      if (action === 'send-specifications') {
        handleSendSpecToInvolvedClick();
      }
      if (action === 'print-sticker') {
        printAddressSticker('Sticker');
      }
      if (action === 'print-address') {
        printAddressSticker('AddressBook');
      }
      if (action === 'print-specification') {
        handlePrintSpecification();
      }
      if (action === 'delete-list-addresses') {
        handleDeleteListAndAddresses();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    handleViewImportLogClick,
    handleExportToCsv,
    handleSendSpecToInvolvedClick,
    printAddressSticker,
    handlePrintSpecification,
    handleDeleteListAndAddresses,
    handleViewShipmentClick,
  ]);

  const paginatorLeft = data && (
    <span>
      {t('Total')}: {data.summary.total_orders}
    </span>
  );

  return (
    <div className="page bulk-orders-list-page">
      <HeaderPages
        title={t('Batch Orders')}
        subtitle={t('View and manage batch orders.')}
        icon={faClipboardList}
      >
        <Button
          type="button"
          label={t('Web import')}
          icon="fas fa-globe"
          className="main-btn"
          disabled={isLoading}
          onClick={handleImportFromWebBtnClick}
          data-cy="web-import-btn"
        />

        <Button
          type="button"
          label={t('Import file')}
          icon="fas fa-file-import"
          className="main-btn p-button-outlined"
          disabled={isLoading}
          onClick={handleImportFromFileBtnClick}
          data-cy="file-import-btn"
        />
      </HeaderPages>

      <WebImportDialog
        isShown={isImportFromWebDialogShown}
        onHide={handleImportFromWebDialogHide}
        reloadImportedLists={reload}
      />

      <ImportFile
        isShown={isImportFromFileDialogShown}
        onHide={handleImportFromFileDialogHide}
        reloadImportedLists={reload}
      />

      <ViewImportLogDialog
        data={dialogData}
        selectedList={contextMenuSelection}
        isShown={isViewLogDialogShown}
        onHide={handleViewLogDialogHide}
      />

      <ViewShipmentsDialog
        selectedList={contextMenuSelection}
        isShown={isViewShipmentsDialogShown}
        onHide={handleViewShipmentsDialogHide}
      />

      <SendSpecToInvolvedDialog
        data={dialogData}
        selectedList={contextMenuSelection}
        isShown={isSendSpecToInvolvedDialogShown}
        onHide={handleSendSpecToInvolvedDialogHide}
      />

      <DeleteImportedListDialog
        selectedList={contextMenuSelection}
        isShown={isDeleteImportedListDialogShown}
        onHide={handleDeleteImportedListDialogHide}
        reloadImportedLists={reload}
      />

      <Dialog
        visible={isLoadingData}
        onHide={() => undefined}
        closable={false}
        closeOnEscape={false}
        resizable={false}
        header={t('Loading...')}
      >
        <ProgressSpinner
          fill="#ccc"
          strokeWidth="4"
          style={{ width: 360, textAlign: 'center', overflow: 'hidden' }}
        />
      </Dialog>

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={220}
        />
        <MainContent>
          <Table
            clearSelectionObj={httpFiltersObj}
            tableRef={tableRef}
            columns={finalColumns}
            data={dataWithNumericParcelsCount}
            isLoading={isLoading}
            hasError={!!error}
            reload={reload}
            isReloadDisabled={!canLoadData}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            filterHeight={220}
            paginatorLeft={paginatorLeft}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelection}
            sortOrder={sortOrder}
            selection={selection}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            exportToCSVButton
            onExportToCSVButtonClick={() =>
              (tableRef?.current as any)?.exportCSV()
            }
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default BulkOrders;
