import {
  faBan,
  faCheckCircle,
  faCheckDouble,
  faDollarSign,
  faFileInvoice,
  faHourglassHalf,
  faKey,
  faMinusCircle,
  faQuestion,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { ClientResource } from '../../../types/api/clients';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { formatPhoneOrMobileNumber } from '../../../utils/helpers/phoneNumbers';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'clients_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    status_id: t('Status'),
    ime: t('Name'),
    email: t('Email'),
    mobilen: t('Mobile'),
    telefon: t('Telephone'),
    mesto_ime: t('Location'),
    adresa: t('Address'),
    faktura: t('Payment'),
    cenovnik_ime: t('Pricelist'),
    referenca: t('Reference Number'),
    modified_by_name: t('Modified by'),
    datum_promena: t('Modified Date'),
    created_by_name: t('Created by'),
    datum_kreiranje: t('Creation Date'),
  };
}

export function getClientStatusIdIconMap(t: TFunction): any {
  return {
    1: { label: t('Pending'), icon: faHourglassHalf },
    2: {
      label: t('Accepted, awaiting e-mail activation'),
      icon: faCheckDouble,
    },
    3: { label: t('Rejected'), icon: faTimes },
    4: { label: t('Active'), icon: faCheckCircle },
    5: { label: t('Deactivated'), icon: faBan },
    6: { label: t('Password reset'), icon: faKey },
    7: { label: t('Deleted'), icon: faMinusCircle },
  };
}

export function getAdditionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>,
  readGuard: boolean,
  editGuard: boolean,
  mergeGuard: boolean,
  statusChangeGuard: boolean,
  resendEmailGuard: boolean,
  forgotPasswordGuard: boolean
) {
  const clientStatusIdIconMap = getClientStatusIdIconMap(t);

  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          textAlign: 'center',
        },
      };

    case 'status_id':
      return {
        body: (row: ClientResource) => {
          const status = clientStatusIdIconMap[row.status_id];

          return (
            <span
              data-tip={status?.label || t('Unknown')}
              data-for="tooltip-right"
            >
              <FontAwesomeIcon icon={status?.icon || faQuestion} />
            </span>
          );
        },
        style: {
          width: 60,
          maxWidth: 100,
        },
        bodyStyle: {
          width: 60,
          maxWidth: 100,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'faktura':
      return {
        body: (row: ClientResource) => (
          <span
            data-tip={!!Number(row.faktura) ? t('Invoice') : t('Cash')}
            data-for="tooltip-right"
          >
            <FontAwesomeIcon
              icon={!!Number(row.faktura) ? faFileInvoice : faDollarSign}
            />
          </span>
        ),
        style: {
          width: 60,
          maxWidth: 100,
        },
        bodyStyle: {
          width: 60,
          maxWidth: 100,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'datum_promena':
    case 'datum_kreiranje':
      return {
        body: (row: any) => dateCell(row[column], true),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'mobilen':
    case 'telefon':
      return {
        body: (row: any) => {
          return row[column]
            ? formatPhoneOrMobileNumber(row[column])
            : emptyCell;
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'ime':
    case 'email':
    case 'modified_by_name':
    case 'created_by_name':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'mesto_ime':
      return {
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'adresa':
    case 'cenovnik_ime':
    case 'referenca':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
    case 'actions':
      return {
        body: (row: any) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View Details'),
                  icon: 'fas fa-search',
                  disabled: !readGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Edit'),
                  icon: 'fas fa-edit',
                  disabled: !editGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Merge'),
                  icon: 'fas fa-people-arrows',
                  disabled: !mergeGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('merge');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Approve'),
                  icon: 'fas fa-thumbs-up',
                  disabled: row.status_id !== 1 || !statusChangeGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('approve');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Reject'),
                  icon: 'fas fa-thumbs-down',
                  disabled: row.status_id !== 1 || !statusChangeGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('reject');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Resend e-mail'),
                  icon: 'fas fa-envelope',
                  disabled: row.status_id !== 2 || !resendEmailGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('resend-email');
                  },
                },
                {
                  label: t('Activate'),
                  icon: 'fas fa-check-circle',

                  disabled: row.status_id !== 5 || !statusChangeGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('activate');
                  },
                },
                {
                  label: t('Deactivate'),
                  icon: 'fas fa-ban',
                  disabled: row?.status_id !== 4 || !statusChangeGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('deactivate');
                  },
                },
                {
                  label: t('Reset password'),
                  icon: 'fas fa-key',
                  disabled: row?.status_id !== 4 || !forgotPasswordGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('reset-password');
                  },
                },
                {
                  label: t('Force set password'),
                  icon: 'fas fa-key',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('force-reset-password');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };
    default:
      return {};
  }
}
