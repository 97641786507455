import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { emptyCell, noColumnStyle } from '../../../../utils/constants/tables';
import { formatPhoneOrMobileNumber } from '../../../../utils/helpers/phoneNumbers';
import ActionsTableMenu from '../../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'warehouses_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    name: t('Name'),
    branch_manager_name: t('Branch Manager'),
    address: t('Address'),
    municipality: t('Place'),
    phone: t('Phone'),
    mobile: t('Mobile Phone'),
    partner_name: t('Partner'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  isViewShown: boolean,
  isEditShown: boolean,
  isDeleteShown: boolean
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'name':
    case 'branch_manager_name':
    case 'partner_name':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'phone':
    case 'mobile':
      return {
        body: (row: any) =>
          typeof row[column] === 'string' && row[column].length > 2
            ? formatPhoneOrMobileNumber(row[column])
            : emptyCell,
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
        },
      };

    case 'municipality':
      return {
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
        },
      };

    case 'address':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'actions':
      return {
        body: (row: any) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View'),
                  icon: 'fas fa-search',
                  disabled: !isViewShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                  },
                },
                {
                  label: t('Edit'),
                  icon: 'fas fa-edit',
                  disabled: !isEditShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                  },
                },
                {
                  label: t('Delete'),
                  icon: 'fas fa-trash',
                  disabled: !isDeleteShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('delete');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };
    default:
      return {};
  }
}
