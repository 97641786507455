import './DeliveryAttemptsView.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../../hooks/useAxios';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import {
  DeliveryAttemptsItem,
  setOperation,
} from '../../DeliveryAttempts.functions';
import { EnlargedImage } from '../EnlargedImage/EnlargedImage';

type Props = {
  selectedRow: DeliveryAttemptsItem | undefined;
  isShown: boolean;
  onHide: () => void;
};

export function DeliveryAttemptsView({
  selectedRow,
  isShown,
  onHide,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 600px)');
  const [imageUrls, setImageUrls] = useState<{ id: number; path: any }[]>([]);

  const { data, isLoading } = useAxiosHook<DeliveryAttemptsItem>(
    {
      url: `/deliverytrackings/${selectedRow?.id}`,
    },
    { skipWhen: !selectedRow?.id }
  );

  useEffect(() => {
    if (!selectedRow?.id) onHide();
  }, [selectedRow, onHide]);

  const { reload: imagesReload } = useAxios();

  useEffect(() => {
    if (data?.images) {
      data.images.forEach((image: any) => {
        imagesReload({
          url: `/deliverytrackings/image/${image.id}`,
          method: 'GET',
          config: { responseType: 'blob' },

          successCallback: (blob: Blob) => {
            const url = URL.createObjectURL(blob);
            setImageUrls((prev) => [...prev, { id: image.id, path: url }]);
          },
        });
      });
    }
  }, [data?.images]); // we only want to rerender when data is changed

  const dialogFooter = (
    <div className="p-pt-4">
      <Button label={t('Close')} onClick={onHide} />
    </div>
  );

  return (
    <Dialog
      visible={isShown}
      onHide={onHide}
      header={isLoading ? t('Loading...') : t('View delivery attempts')}
      footer={dialogFooter}
      dismissableMask
      blockScroll
      maximizable
      maximized={isOnMobile}
      className="view-delivery-attempts-dialog"
    >
      {isLoading ? (
        <DialogSpinner />
      ) : (
        <>
          <h4>{t('General information')}</h4>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td>
                  <b>{t('Serial Number')}</b>
                </td>
                <td>{data?.serialNumber ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Date')}</b>
                </td>
                <td>{data?.date ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Operation')}</b>
                </td>
                <td>{setOperation(data?.operation!, t) ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Courier Name')}</b>
                </td>
                <td>{data?.courierName ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Customer Name')}</b>
                </td>
                <td>{data?.customerName ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Reason Name')}</b>
                </td>
                <td>{data?.reasonName ?? '-'}</td>
              </tr>

              <tr>
                <td>
                  <b>{t('Comment')}</b>
                </td>
                <td>{data?.comment ?? '-'}</td>
              </tr>
            </tbody>
          </table>
          <h4>{t('Images')}</h4>
          <EnlargedImage imagesUrls={imageUrls} />
        </>
      )}
    </Dialog>
  );
}
