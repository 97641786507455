import './BulkOrders.scss';

import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { OrderStatus } from '../../../enums/orders';
import { noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export type BulkOrder = {
  barcode: string;
  client_name: string;
  created: string;
  delivery_date: null;
  enable_deleting: null;
  id: number;
  import_all_or_none: number;
  import_list_type_id: number;
  import_list_type_name: string;
  ip: string;
  max_status_id: number;
  name: string;
  parcels_count: number;
  status: string;
  status_tip: string;
  stock_status_id: number;
};

export type BulkOrderParties = {
  id: number;
  name: string;
  email: string;
  party: number;
};

export const tableStorageKey = 'importedLists_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    import_list_type_name: t('Batch Order Template'),
    status: t('Status'),
    name: t('Name'),
    created: t('Created at'),
    client_name: t('Client'),
    barcode: t('Barcode'),
    parcels_count: t('Total'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>,
  exportToCsvGuard: boolean,
  readImportLogGuard: boolean,
  readShipmentsGuard: boolean,
  sendSpecToInvolvedGuard: boolean,
  deleteImportedListGuard: boolean
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          textAlign: 'center',
        },
      };

    case 'parcels_count':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'import_list_type_name':
      return {
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'name':
      return {
        style: {
          width: 360,
        },
        bodyStyle: {
          width: 360,
        },
      };

    case 'status':
      return {
        body: (row: BulkOrder) => (
          <span className={`status-badge ${row['status_tip']}`}>
            {row[column]}
          </span>
        ),
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'barcode':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'client_name':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'created':
      return {
        body: (row: BulkOrder) => dateCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'actions':
      return {
        body: (row: BulkOrder) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View import log'),
                  icon: 'fas fa-boxes',
                  disabled: !readImportLogGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-import-log');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('View shipments'),
                  icon: 'fas fa-edit',
                  disabled: !readShipmentsGuard || row.parcels_count === 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-shipments');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Export to CSV'),
                  icon: 'fas fa-file-csv',
                  disabled: !exportToCsvGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('export-to-csv');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Send specifications to involved'),
                  icon: 'fas fa-paper-plane',
                  disabled: !sendSpecToInvolvedGuard || row.parcels_count === 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('send-specifications');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Print sticker'),
                  icon: 'fas fa-ticket-alt',
                  disabled: row.parcels_count === 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-sticker');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Print address book'),
                  icon: 'fas fa-address-book',
                  disabled: row.parcels_count === 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-address');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Print specification'),
                  icon: 'fas fa-clipboard-list',
                  disabled: row.parcels_count === 0,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('print-specification');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Delete list and addresses'),
                  icon: 'fas fa-trash',
                  disabled:
                    !deleteImportedListGuard || row.status_tip >= 'processing',
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('delete-list-addresses');
                    setCaller('context-menu');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}
