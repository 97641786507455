import classNames from 'classnames';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import FiltersCounter from '../Components/Filters/FiltersCounter';
import { lockerStatuses } from './LockersList.functions';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();
  const location = useLocation();
  const [title, setTitle] = useState<string>(
    () => getSearchQueryParam(location.search, 'title') ?? ''
  );
  const [placeName, setPlacename] = useState<string>(
    () => getSearchQueryParam(location.search, 'placeName') ?? ''
  );
  const [address, setAddress] = useState<string>(
    () => getSearchQueryParam(location.search, 'address') ?? ''
  );
  const [status, setStatus] = useState<string>(
    () => getSearchQueryParam(location.search, 'online') ?? ''
  );

  const statusesOptions = useMemo(() => lockerStatuses(t), [t]);

  const debouncedTitle = useDebounce(title, debounceTimeout);
  const debouncedPlacename = useDebounce(placeName, debounceTimeout);
  const debouncedAddress = useDebounce(address, debounceTimeout);

  const filtersArr = useMemo(
    () => [
      {
        debouncedTitle,
        debouncedPlacename,
        debouncedAddress,
        status,
      },
    ],
    [debouncedTitle, debouncedPlacename, debouncedAddress, status]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [debouncedTitle, debouncedPlacename, debouncedAddress, status, setPage]);

  const httpFiltersObj = useMemo(
    () => ({
      title: debouncedTitle,
      placeName: debouncedPlacename,
      address: debouncedAddress,
      online: status,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      debouncedTitle,
      debouncedPlacename,
      debouncedAddress,
      status,
      haveFiltersChanged,
      page,
      limit,
    ]
  );

  useSearchQueryParam('title', debouncedTitle);
  useSearchQueryParam('address', debouncedAddress);
  useSearchQueryParam('placeName', debouncedPlacename);
  useSearchQueryDropdownParam('online', status, setStatus, statusesOptions);

  const basicFiltersActiveFilterCount = Object.values({
    title,
    placeName,
    address,
    status,
  }).filter(Boolean).length;

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab
          disabled
          headerTemplate={
            <FiltersCounter
              description={t('Basic filters')}
              counter={basicFiltersActiveFilterCount}
            />
          }
        >
          <div className="sidebar_filter">
            <label htmlFor="serial_filter">{t('Name')}</label>
            <InputText
              id="serial_filter"
              value={title ?? ''}
              onChange={(e) => setTitle(e.target.value)}
              className={classNames({
                sidebar_filter_active: title,
              })}
            />
          </div>{' '}
          <div className="sidebar_filter">
            <label htmlFor="serial_filter">{t('Municipality')}</label>
            <InputText
              id="serial_filter"
              value={placeName ?? ''}
              onChange={(e) => setPlacename(e.target.value)}
              className={classNames({
                sidebar_filter_active: placeName,
              })}
            />
          </div>{' '}
          <div className="sidebar_filter">
            <label htmlFor="serial_filter">{t('Address')}</label>
            <InputText
              id="serial_filter"
              value={address ?? ''}
              onChange={(e) => setAddress(e.target.value)}
              className={classNames({
                sidebar_filter_active: address,
              })}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="status_id_filter">{t('Status')}</label>
            <Dropdown
              id="status_id_filter"
              options={statusesOptions}
              value={status}
              onChange={(e: DropdownChangeParams) => setStatus(e.value)}
              showClear
              className={classNames({
                sidebar_filter_active: status,
              })}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      title,
      placeName,
      address,
      status,
      statusesOptions,
      basicFiltersActiveFilterCount,
      t,
    ]
  );

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  function resetAllFilters() {
    setTitle('');
    setPlacename('');
    setAddress('');
    setStatus('');
  }

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
