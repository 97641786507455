import { useFormikContext } from 'formik';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../../hooks/useAxios';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import { isStreetFreeInputAllowed } from '../../../../../utils/constants/misc';
import { queryString } from '../../../../../utils/helpers/http';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import {
  customerConfigsOnly,
  generateInvoicingCheckboxProperties,
  isHeadquarter,
  tabIcon,
} from './CreateEditDialog.functions';
import ChargingDepartmentsTab from './Tabs/ChargingDepartmentsTab';
import Discounts from './Tabs/Discounts/Discounts';
import EmailConfiguration from './Tabs/EmailConfiguration/EmailConfiguration';
import NotificationsTab from './Tabs/Fields/NotificationsTab/NotificationsTab';
import GeneralInfoTab from './Tabs/GeneralInfoTab';
import LegalDataTab from './Tabs/LegalDataTab';
import OrdersTab from './Tabs/OrdersTab';

function DialogContainer({
  isShown,
  isEditDialog,
  clientData,
  isClientDataLoading,
  headquarterData,
  isHeadquarterDataLoading,
  reloadHeadquarterData,
  headquarterSubsidiaries,
  chargingDepartmentsData,
  configsData,
}) {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const fieldSettings = customerConfigsOnly(configsData?.data);

  const { data: priceLists, isLoading: arePriceListsLoading } = useAxiosHook(
    '/price-lists?limit=0'
  );

  useAxios(`/places/${values.mesto_id}`, undefined, {
    skipWhen: isClientDataLoading || !values.mesto_id,
    successCallback: (place) => {
      setFieldValue('place', place.ime);
      setFieldValue('municipality', place.opstina_id);
      setFieldValue('municipality_id', place.opstina_id);
    },
  });

  const productServicesCollectionRequest = useAxiosHook(
    '/product-services' +
      queryString({
        page: 1,
        limit: 0,
      })
  );

  const { data: municipalities, loading: isMunicipalitiesLoading } =
    useAxiosHook('/municipalities');

  const {
    data: places,
    reload: reloadPlaces,
    isLoading: isPlacesLoading,
  } = useAxios(`/municipalities/${values.municipality_id}/places`, undefined, {
    skipWhen: !values.municipality_id,
  });

  const {
    data: streets,
    reload: reloadStreets,
    isLoading: isStreetsLoading,
  } = useAxios(`/places/${values.mesto_id}/streets`, undefined, {
    skipWhen:
      isClientDataLoading || !values.mesto_id || isStreetFreeInputAllowed,
    successCallback: (streets) => {
      if (values.ulica_id) {
        const street = streets.find((s) => s.id === values.ulica_id);
        setFieldValue('street', street?.ime);
        setFieldValue('ulica_ime', street?.ime);
      }
    },
  });

  const { data: occupations, isLoading: isOccupationsLoading } =
    useAxiosHook('/occupations');

  const { data: smsGateways } = useAxiosHook('/clients/sms/gateways');

  const { data: defaultNotificationsData } = useAxiosHook(
    '/notifications/default'
  );

  const clientTypeOptions = [
    { label: t('Individual entity'), value: false },
    { label: t('Legal entity'), value: true },
  ];

  const occupationOptions = occupations?.map((m) => ({
    label: m.ime,
    value: m.id,
  }));

  const municipalitiesOptions = municipalities?.map((m) => ({
    label: m.ime,
    value: m.id,
  }));

  const placesOptions = places?.map((m) => ({
    label: m.ime,
    value: m.ime,
    postal_code: m.postenski_broj,
  }));

  const streetsOptions =
    streets?.length > 0
      ? streets.map((m) => ({ label: m.ime, value: m.ime }))
      : [];

  const priceListOptions = priceLists?.data.map((p) => ({
    label: p.name ?? '',
    value: p.id,
  }));

  const smsGatewayOptions = useMemo(
    () => [
      { label: t("Don't notify"), value: '' },
      { label: t('Default Provider'), value: 'default' },
      ...(Array.isArray(smsGateways) ? smsGateways : [])
        .filter((g) => typeof g === 'string')
        .map((g) => ({ label: g, value: g })),
    ],
    [smsGateways, t]
  );

  const defaultNotifications = useMemo(() => {
    if (!(defaultNotificationsData?.length > 0)) {
      return;
    }

    let returnObj = {};

    for (const n of defaultNotificationsData) {
      returnObj[`${n.event}-${n.actor}-${n.channel}`] = n;
    }

    return returnObj;
  }, [defaultNotificationsData]);

  const invoicingAndCODDepartmentOptions = useMemo(
    () =>
      values.departments.map((department) => ({
        label: department.name,
        value: department.id,
      })),

    [values.departments]
  );

  const invoicingCheckboxProperties = useMemo(
    () =>
      generateInvoicingCheckboxProperties(
        values,
        isHeadquarter(values, clientData),
        headquarterData,
        clientData,
        t
      ),
    [clientData, headquarterData, t, values]
  );

  useEffect(() => {
    setActiveTabIndex(0);

    return () => {
      setActiveTabIndex(0);
    };
  }, [isShown]);

  const generalInfoTabIcon = tabIcon(
    t,
    'general-info',
    values,
    errors,
    touched
  );

  const legalDataTabIcon = tabIcon(
    t,
    'legal-data',
    values,
    errors,
    touched,
    isEditDialog
  );

  const discountsTabIcon = tabIcon(
    t,
    'discounts',
    values,
    errors,
    touched,
    isEditDialog
  );

  const ordersTabIcon = tabIcon(
    t,
    'orders',
    values,
    errors,
    touched,
    isEditDialog
  );

  const notificationsTabIcon = tabIcon(
    t,
    'notifications',
    values,
    errors,
    touched,
    isEditDialog
  );

  const chargingDepartmentsTabIcon = tabIcon(
    t,
    'charging-departments',
    values,
    errors,
    touched,
    isEditDialog
  );

  const emailConfigurationTabIcon = tabIcon(
    t,
    'email-configuration',
    values,
    errors,
    touched,
    isEditDialog
  );

  if (isClientDataLoading) {
    return <DialogSpinner />;
  }

  return (
    <TabView
      activeIndex={activeTabIndex}
      onTabChange={(e) => setActiveTabIndex(e.index)}
      className={
        Object.keys(errors).some((k) => Object.keys(touched).includes(k))
          ? 'invalid'
          : ''
      }
    >
      <TabPanel header={t('General info')} {...generalInfoTabIcon}>
        <GeneralInfoTab
          clientData={clientData}
          clientTypeOptions={clientTypeOptions}
          municipalitiesOptions={municipalitiesOptions}
          isMunicipalitiesLoading={isMunicipalitiesLoading}
          places={places}
          placesOptions={placesOptions}
          isPlacesLoading={isPlacesLoading}
          reloadPlaces={reloadPlaces}
          streets={streets}
          streetsOptions={streetsOptions}
          isStreetsLoading={isStreetsLoading}
          reloadStreets={reloadStreets}
          priceListOptions={priceListOptions}
          arePriceListsLoading={arePriceListsLoading}
          occupationOptions={occupationOptions}
          isOccupationsLoading={isOccupationsLoading}
          isEditDialog={isEditDialog}
        />
      </TabPanel>

      <TabPanel
        header={t('Legal data')}
        // headerStyle={values.pravno_lice ? {} : { display: 'none' }}
        headerClassName="data-cy-legal-data-tab"
        {...legalDataTabIcon}
      >
        <LegalDataTab
          clientData={clientData}
          priceListOptions={priceListOptions}
          arePriceListsLoading={arePriceListsLoading}
          invoicingAndCODDepartmentOptions={invoicingAndCODDepartmentOptions}
          isEditDialog={isEditDialog}
          invoicingCheckboxProperties={invoicingCheckboxProperties}
          fieldSettings={fieldSettings}
        />
      </TabPanel>

      <TabPanel
        header={t('Discounts')}
        headerStyle={values.pravno_lice ? {} : { display: 'none' }}
        headerClassName="data-cy-discounts-data-tab"
        {...discountsTabIcon}
      >
        <Discounts
          productServicesCollectionRequest={productServicesCollectionRequest}
          disabled={!invoicingCheckboxProperties.checked}
        />
      </TabPanel>

      <TabPanel
        header={t('Orders')}
        headerClassName="data-cy-orders-tab"
        {...ordersTabIcon}
      >
        <OrdersTab />
      </TabPanel>

      <TabPanel
        header={t('Notifications')}
        headerClassName="data-cy-notifications-tab"
        {...notificationsTabIcon}
      >
        <NotificationsTab
          defaultNotifications={defaultNotifications}
          smsGatewayOptions={smsGatewayOptions}
        />
      </TabPanel>

      <TabPanel
        header={t('Charging Departments')}
        headerStyle={
          values.pravno_lice &&
          ((isEditDialog && clientData?.id === values?.direkcija_id) ||
            (!isEditDialog && values.direkcija_id === 0))
            ? {}
            : { display: 'none' }
        }
        headerClassName="data-cy-charging-departments"
        {...chargingDepartmentsTabIcon}
      >
        <ChargingDepartmentsTab
          clientData={clientData}
          isEditDialog={isEditDialog}
          headquarterSubsidiaries={headquarterSubsidiaries}
        />
      </TabPanel>

      <TabPanel
        header={t('Email Configuration')}
        {...emailConfigurationTabIcon}
      >
        <EmailConfiguration
          clientData={clientData}
          headquarterSubsidiaries={headquarterSubsidiaries}
        />
      </TabPanel>
    </TabView>
  );
}

export default DialogContainer;
