import {
  faCheckCircle,
  faQuestion,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'react-i18next';

import { WithPagination } from '../../../types/api';
import { noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export const tableStorageKey = 'vehicleManagement_dataTable';

export function getColumnHeadersMap(
  t: TFunction
): Partial<Record<keyof Vehicle | 'no', string>> {
  return {
    no: t('No.'),
    aktivno: t('Active.IT'),
    marka_name: t('Brand'),
    model_name: t('Model'),
    registarska_tablicka: t('Registration Plates'),
    datum_registracija: t('Registration Date'),
    posleden_servis: t('Last Service'),
  };
}

export type Vehicle = {
  id: number;
  aktivno: number;
  komentar: string | null;
  marka_id: number;
  marka_name: string;
  model_id: number;
  model_name: string;
  posleden_servis: string;
  datum_registracija: string;
  registarska_tablicka: string;
  volume: number | null;
};

export type ApiData = WithPagination<Vehicle[]>;

function getStatusIconMap(
  t: TFunction
): Record<number, { label: string; icon: any }> {
  return {
    0: { label: t('Inactive'), icon: faTimes },
    1: { label: t('Active.IT'), icon: faCheckCircle },
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap>
) {
  const statusIconMap = getStatusIconMap(t);

  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'aktivno':
      return {
        body: (row: Vehicle) => (
          <span
            data-tip={statusIconMap[row.aktivno]?.label || t('Unknown')}
            data-for="tooltip-right"
          >
            <FontAwesomeIcon
              icon={statusIconMap[row.aktivno]?.icon || faQuestion}
            />
          </span>
        ),
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'marka_name':
    case 'model_name':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    case 'registarska_tablicka':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'datum_registracija':
    case 'posleden_servis':
      return {
        body: (row: Vehicle) => dateCell(row[column], true),
        style: {
          width: 220,
        },
        bodyStyle: {
          width: 220,
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  handleCMViewClick: () => void,
  isEditShown: boolean,
  handleCMEditClick: () => void,
  isDeleteShown: boolean,
  handleCMDeleteClick: () => void
) {
  const viewOption = {
    label: t('View'),
    icon: 'fas fa-search',
    command: handleCMViewClick,
  };

  const editOption = {
    label: t('Edit'),
    icon: 'fas fa-edit',
    command: handleCMEditClick,
  };

  const deleteOption = {
    label: t('Delete'),
    icon: 'fas fa-trash',
    command: handleCMDeleteClick,
  };

  return contextMenuModel([
    [[true, viewOption]],
    [[isEditShown, editOption]],
    [[isDeleteShown, deleteOption]],
  ]);
}
