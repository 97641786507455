function Footer({ isMenuExpanded }: { isMenuExpanded: boolean }) {
  return (
    <div
      style={
        isMenuExpanded
          ? {
              display: 'flex',
              justifyContent: 'center',
              padding: '15px',
              fontSize: 'smaller',
              color: '#4a5058',
            }
          : {
              paddingBottom: '15px',
              textAlign: 'center',
              fontSize: 'smaller',
              color: '#4a5058',
            }
      }
    >
      <footer>
        <span>
          {isMenuExpanded && <span>Copyright</span>} ©{' '}
          <a href="https://koder.mk/" target="_blank" rel="noreferrer">
            Koder
          </a>{' '}
          {isMenuExpanded && <span>{new Date().getFullYear()}</span>}
        </span>
      </footer>
    </div>
  );
}

export default Footer;
