import {
  faCheckCircle,
  faChevronCircleRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { ColumnProps } from 'primereact/column';
import { TFunction } from 'react-i18next';

import { EmployeeUserDeviceCollection } from '../../../types/api/employees';
import { ContextMenuItem } from '../../../types/primereact';
import {
  TableColumnHeadersMap,
  TableColumns,
  Unpacked,
} from '../../../types/util';
import { emptyCell, noColumnStyle } from '../../../utils/constants/tables';
import { dateFormat } from '../../../utils/helpers/formatting';
import { tryInt } from '../../../utils/helpers/parse';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export type Row = Unpacked<EmployeeUserDeviceCollection>;

export type Column = TableColumns<
  Pick<
    Row,
    | 'username'
    | 'name'
    | 'surname'
    | 'device_id'
    | 'device_name'
    | 'last_login'
    | 'app_version'
    | 'app_name'
    | 'activated'
    | 'created'
  >
>;

export const tableStorageKey = 'deviceManagement_dataTable';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    activated: t('Status'),
    name: t('Name'),
    surname: t('Surname'),
    username: t('Username'),
    device_id: t('Device ID'),
    device_name: t('Device name'),
    created: t('Created'),
    last_login: t('Last login'),
    app_name: t('App name'),
    app_version: t('App version'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap>
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          textAlign: 'center',
        },
      };

    case 'activated':
      return {
        body: (row: Row) => {
          const isActive = !!parseInt(row[column]);

          return (
            <span
              data-tip={isActive ? t('Active') : t('Inactive')}
              data-for="tooltip-right"
            >
              <FontAwesomeIcon icon={isActive ? faCheckCircle : faTimes} />
            </span>
          );
        },
        style: {
          width: 60,
          maxWidth: 110,
        },
        bodyStyle: {
          width: 60,
          maxWidth: 110,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'device_id':
      return {
        body: (row: Row) => (
          <span data-tip={row[column]} data-for="tooltip-right">
            {`${row[column]?.slice(0, 8)} ... `}
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="secondary-icon"
            />
          </span>
        ),
        style: {
          width: 160,
        },
        bodyStyle: {
          width: 160,
        },
      };

    case 'created':
    case 'last_login':
      return {
        body: (row: Row) =>
          row[column] ? (
            <span data-tip={dateFormat(row[column]!)} data-for="tooltip-right">
              {dayjs(row[column]!).fromNow()}
            </span>
          ) : (
            emptyCell
          ),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'name':
    case 'surname':
      return {
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'username':
    case 'device_name':
      return {
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    default:
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };
  }
}

export function generateContextMenu({
  t,
  selection,
  isApproveShown,
  handleApprove,
  isDenyShown,
  handleDeny,
  isDeleteShown,
  handleDelete,
}: {
  t: TFunction;
  selection: Row | null;
  isApproveShown: boolean;
  handleApprove: () => void;
  isDenyShown: boolean;
  handleDeny: () => void;
  isDeleteShown: boolean;
  handleDelete: () => void;
}): ContextMenuItem[] {
  const approveItem = {
    label: t('Approve'),
    icon: 'fas fa-check-circle',
    command: handleApprove,
  };

  const denyItem = {
    label: t('Deny'),
    icon: 'fas fa-ban',
    command: handleDeny,
  };

  const deleteItem = {
    label: t('Delete'),
    icon: 'fas fa-trash',
    command: handleDelete,
  };

  const isActivated = !!tryInt(selection?.activated);

  return contextMenuModel([
    [
      [!isActivated && isApproveShown, approveItem],
      [!isActivated && isDenyShown, denyItem],
    ],
    [[isActivated && isDeleteShown, deleteItem]],
  ]);
}
