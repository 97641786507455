import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { Tariff, TariffCollection } from '../../../types/api/tariffs';
import { Unpacked } from '../../../types/util';
import { noColumnStyle } from '../../../utils/constants/tables';
import { dateCell } from '../../../utils/helpers/dataTable';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'tariffs_dataTable';

export type Row = Unpacked<TariffCollection>;

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    name: t('Tariff'),
    created_at: t('Created at'),
    created_by_full_name: t('Created by'),
    modified_at: t('Modified at'),
    modified_by_full_name: t('Modified by'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column:
    | keyof ReturnType<typeof getColumnHeadersMap>
    | 'time_from_pickup'
    | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>,
  editGuard: boolean,
  deleteGuard: boolean
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'name':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'created_at':
    case 'modified_at':
      return {
        body: (row: Tariff) => dateCell(row[column] ?? ''),
        style: {
          width: 220,
        },
        bodyStyle: {
          width: 220,
        },
      };

    case 'created_by_full_name':
    case 'modified_by_full_name':
      return {
        style: {
          width: 220,
        },
        bodyStyle: {
          width: 220,
        },
      };

    case 'actions':
      return {
        body: (row: Tariff) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View Details'),
                  icon: 'fas fa-search',
                  disabled: false,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Edit'),
                  icon: 'fas fa-edit',
                  disabled: !editGuard,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('edit');
                    setCaller('context-menu');
                  },
                },
                {
                  label: t('Delete tariff'),
                  icon: 'fas fa-trash',
                  disabled: !deleteGuard || Boolean(row.is_order_closed),
                  command: () => {
                    setContextMenuSelection(row);
                    setCaller('context-menu');
                    setAction('delete-order');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };

    default:
      return {};
  }
}
