import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { AxiosRequestConfig } from 'axios';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getAssignmentAndReceptionStatusSeveritySound,
  getWarehouseCourierOrderAssignmentStatusConfig,
} from '../../../../../configs/orders';
import ToastContext from '../../../../../context/ToastContext';
import { IntBool } from '../../../../../enums/booleans';
import {
  OrderStatus,
  WarehouseAssignmentAndReceptionStatusSeverity,
} from '../../../../../enums/orders';
import { UserRole } from '../../../../../enums/users';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../../../hooks/useEndpointGuard';
import usePageTitle from '../../../../../hooks/usePageTitle';
import usePreventWindowClose from '../../../../../hooks/usePreventWindowClose';
import usePrevious from '../../../../../hooks/usePrevious';
import useRouteDialog from '../../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../../hooks/useTableColumns';
import useTableState from '../../../../../hooks/useTableState';
import useToastMessage from '../../../../../hooks/useToastMessage';
import {
  AvailableCouriersCollection,
  AvailableCouriersCollectionQueryParams,
} from '../../../../../types/api/couriers';
import {
  CourierSummaryCollection,
  CourierSummaryCollectionQueryParams,
  DeleteTransferCourierAssignment,
  TransferCourierReceptionCollection,
  TransferCourierReceptionCollectionQueryParams,
  TransferCourierSummaryCollection,
  TransferCourierSummaryCollectionQueryParams,
  UndoAssignedShipmentQueryParams,
  UndoTransferCourierReceivedShipmentQueryParams,
  UpdateCourierDeliveryAssignment,
  UpdateCourierDeliveryAssignmentRequestPayload,
  UpdateTransferCourierReceptionRequestPayload,
} from '../../../../../types/api/orders';
import { EntityIdRouteParams } from '../../../../../types/routing';
import { Unpacked } from '../../../../../types/util';
import api from '../../../../../utils/api';
import {
  cancelShipmentAssignmentGuard,
  loadShipmentGuard,
} from '../../../../../utils/constants/auth/transferCourierToCourier';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../../../utils/constants/routePaths';
import { playAudio } from '../../../../../utils/helpers/audio';
import { sequential } from '../../../../../utils/helpers/functions';
import { queryString } from '../../../../../utils/helpers/http';
import { tryInt } from '../../../../../utils/helpers/parse';
import {
  errorToast,
  successToast,
  warnToast,
} from '../../../../../utils/helpers/primereact';
import Table from '../../../../DataTable/Table/Table';
import Flex from '../../../../layout/flex/Flex';
import MainContent from '../../../../layout/flex/MainContent';
import DetailsSection from '../../../Components/DetailsSection/DetailsSection';
import HeaderPages from '../../../Components/HeaderPages/HeaderPages';
import ViewActiveOrderDialog from '../../../Orders/Dialogs/View/ViewActiveOrderDialog';
import { SingleOrder } from '../../../Orders/Orders.functions';
import CommonStateContext from '../../_AssignmentAndReception/CommonStateContext';
import CreateWorkOrderDialog from '../../_AssignmentAndReception/Dialogs/Create/WorkOrder/CreateWorkOrderDialog';
import WorkOrderNotCreatedDialog from '../../_AssignmentAndReception/Dialogs/Create/WorkOrder/WorkOrderNotCreatedDialog';
import Barcode from '../../_AssignmentAndReception/Sidebar/Barcode';
import Filters from '../../_AssignmentAndReception/Sidebar/Filters';
import {
  CandidatesFilter,
  WarehousePage,
} from '../../_AssignmentAndReception/types';
import useCommonState from '../../_AssignmentAndReception/useCommonState';
import Stats from './Sidebar/Stats';
import {
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
  tableStorageKey,
} from './TransferCourierToCourier.functions';

function TransferCourierToCourier(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(
    t('Transfer Courier shipment reception and Courier delivery assignment')
  );

  // Prevent entire page being closed unintentionally.
  usePreventWindowClose();

  const { toastRef } = useContext(ToastContext);

  const loadGuard = useEndpointGuard(loadShipmentGuard);
  const cancelGuard = useEndpointGuard(cancelShipmentAssignmentGuard);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const state = useCommonState({
    isTransferCourierFilterShown: true,
    page: WarehousePage.TransferCourierToCourier,
  });

  const {
    candidatesFilter,
    debouncedSerialNumberFilter,
    isCreateWorkOrderDialogShown,
    handleCreateWorkOrderDialogSuccess,
    handleCreateWorkOrderDialogHide,
    isWorkOrderNotOpenDialogShown,
    handleWorkOrderNotOpenDialogHide,
    isDateValid,
    courierWorkOrderId,
    transferCourierWorkOrderId,
    hasTransferCourierWorkOrder,
    hasCourierWorkOrder,
    transferCourierFilter,
    courierFilter,
    rowClassName,
    nativeHubId,
  } = state;

  const {
    page,
    limit,
    tableRef,
    sortField,
    sortOrder,
    selection,
    setPage,
    setLimit,
    setSortField,
    setSortOrder,
    setSelection,
  } =
    useTableState<Unpacked<TransferCourierReceptionCollection>>(
      tableStorageKey
    );

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      'warehouse_transferCourierToCourier',
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties(t)
    );

  const { data, error, isLoading, reload } =
    useAxiosHook<TransferCourierReceptionCollection>(
      '/orders/transfer/reception' +
        queryString<TransferCourierReceptionCollectionQueryParams>({
          work_order_id: transferCourierWorkOrderId ?? '',
          show_candidates:
            isDateValid && candidatesFilter === CandidatesFilter.Hidden ? 0 : 1,
          sort_is_candidate:
            candidatesFilter === CandidatesFilter.Last ? 'asc' : 'desc',
          serial_number: debouncedSerialNumberFilter,
          page: page,
          limit: limit,
        }),
      { skipWhen: !hasTransferCourierWorkOrder }
    );

  const availableTransferCouriersCollectionRequest =
    useAxiosHook<AvailableCouriersCollection>(
      '/couriers/available' +
        queryString<AvailableCouriersCollectionQueryParams>({
          is_assigned_to_region: IntBool.False,
          user_role_id: UserRole.TransferCourier,
        })
    );

  const availableCouriersCollectionRequest =
    useAxiosHook<AvailableCouriersCollection>(
      '/couriers/available' +
        queryString<AvailableCouriersCollectionQueryParams>({
          is_assigned_to_region: IntBool.True,
          user_role_id: UserRole.Courier,
        })
    );

  const transferCourierStatsRequest =
    useAxiosHook<TransferCourierSummaryCollection>(
      '/orders/transfer/summary' +
        queryString<TransferCourierSummaryCollectionQueryParams>({
          work_order_id: transferCourierWorkOrderId ?? '',
        }),
      { skipWhen: !hasTransferCourierWorkOrder }
    );

  const courierStatsRequest = useAxiosHook<CourierSummaryCollection>(
    '/orders/courier/summary' +
      queryString<CourierSummaryCollectionQueryParams>({
        work_order_id: transferCourierWorkOrderId ?? '',
        hub_id: nativeHubId!,
      }),
    { skipWhen: !hasTransferCourierWorkOrder }
  );

  useToastMessage(
    undefined,
    transferCourierStatsRequest.error || courierStatsRequest.error,
    {
      error: {
        summary: t('An error occured while reading stats data.'),
      },
    }
  );

  const {
    data: assignOrderData,
    error: assignOrderError,
    reload: assignOrderReload,
  } = useAxiosHook<UpdateCourierDeliveryAssignment>();

  const handleValidBarcodeScan = useCallback(
    (barcode: string) => {
      if (!barcode.length) {
        return;
      }

      const transferCourierReceptionData: UpdateTransferCourierReceptionRequestPayload =
        {
          work_order_id: transferCourierWorkOrderId!,
          serial_number: barcode,
        };

      const courierDeliveryAssignmentData: UpdateCourierDeliveryAssignmentRequestPayload =
        {
          work_order_id: courierWorkOrderId!,
          serial_number: barcode,
        };

      // The requests have to be sent in a sequential order
      api({
        url: '/orders/transfer/reception',
        method: 'PUT',
        data: transferCourierReceptionData,
      })
        .then(() => {
          assignOrderReload({
            url: '/orders/courier/assignments/delivery',
            method: 'PUT',
            data: courierDeliveryAssignmentData,
          });
        })
        .catch(() => {
          errorToast(
            toastRef,
            t('An error occured while trying to receive shipment.'),
            t('Error')
          );
        });
    },
    [
      assignOrderReload,
      courierWorkOrderId,
      t,
      toastRef,
      transferCourierWorkOrderId,
    ]
  );

  const prevAssignOrderData = usePrevious(assignOrderData);

  useToastMessage(undefined, assignOrderError, {
    error: {
      summary: t('An error occured while trying to assign shipment.'),
    },
  });

  useEffect(() => {
    if (!assignOrderData || assignOrderData === prevAssignOrderData) {
      return;
    }

    const warehouseOrderAssignmentStatusConfig =
      getWarehouseCourierOrderAssignmentStatusConfig(t);

    const status = assignOrderData.assignment_status_id;

    const config = warehouseOrderAssignmentStatusConfig[status];

    switch (config.severity) {
      case WarehouseAssignmentAndReceptionStatusSeverity.Info:
        successToast(toastRef, t('Success'), config.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Warning:
        warnToast(toastRef, t('Warning'), config.message);
        break;

      case WarehouseAssignmentAndReceptionStatusSeverity.Error:
        errorToast(toastRef, t('Error'), config.message);
        break;
    }

    const assignmentStatusSound = getAssignmentAndReceptionStatusSeveritySound(
      config.severity,
      true
    );

    if (assignmentStatusSound) {
      playAudio(assignmentStatusSound);
    }

    if (
      config.severity !== WarehouseAssignmentAndReceptionStatusSeverity.Error
    ) {
      reload();
      transferCourierStatsRequest.reload();
    }
  }, [
    assignOrderData,
    prevAssignOrderData,
    reload,
    transferCourierStatsRequest,
    t,
    toastRef,
  ]);

  const { id } = useParams<EntityIdRouteParams>();

  const {
    show: showViewOrderDialog,
    hide: hideViewOrderDialog,
    isVisible: isViewOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.TransfersCourierToCourier,
    constructIdRoute(
      RoutePaths.ViewTransferCourierToCourier,
      id ?? selection?.order_id
    )
  );

  const { data: orderData, isLoading: isOrderDataLoading } =
    useAxiosHook<SingleOrder>(
      {
        url: `/orders/${id}`,
      },
      {
        skipWhen: !isViewOrderDialogVisible,
      }
    );

  const {
    data: cancelShipmentData,
    error: cancelShipmentError,
    reload: cancelShipmentReload,
  } = useAxiosHook<DeleteTransferCourierAssignment>();

  useToastMessage(cancelShipmentData, cancelShipmentError, {
    success: {
      summary: t('Successfully cancelled assignment for shipment {{id}}.', {
        id: cancelShipmentData?.serial_number ?? '',
      }),
      callback: () => sequential(reload, transferCourierStatsRequest.reload),
    },
    error: {
      summary: t(
        'An error occured while trying to cancel the shipment assignment.'
      ),
      callback: () => sequential(reload, transferCourierStatsRequest.reload),
    },
  });

  const receiveAndAssignShipment = useCallback(() => {
    if (!selection?.serial_number) {
      return;
    }

    handleValidBarcodeScan(selection.serial_number);
  }, [handleValidBarcodeScan, selection?.serial_number]);

  const contextMenuModel = useMemo(() => {
    // true = received and assigned
    // false = received only
    const isAssignedToCourier =
      !!selection?.status_id &&
      selection.status_id >= OrderStatus.HandedOverToADeliveryCourier;

    function handleCancelCMClick() {
      if (!selection) {
        return;
      }

      const undoHubShipmentReceptionConfig: AxiosRequestConfig = {
        url:
          `/orders/transfer/reception/${selection?.order_id}` +
          queryString<UndoTransferCourierReceivedShipmentQueryParams>({
            work_order_id: transferCourierWorkOrderId!,
            transfer_work_order_id: selection?.transfer_work_order_id ?? '',
          }),
        method: 'DELETE',
      };

      if (isAssignedToCourier) {
        api({
          url:
            `/orders/courier/assignments/delivery/${selection.order_id}` +
            queryString<UndoAssignedShipmentQueryParams>({
              work_order_id: courierWorkOrderId!,
            }),
          method: 'DELETE',
        })
          .then(() => {
            cancelShipmentReload(undoHubShipmentReceptionConfig);
          })
          .catch(() => {
            errorToast(
              toastRef,
              t('Error'),
              t(
                'An error occured while trying to cancel the shipment assignment.'
              )
            );
          });
      } else {
        cancelShipmentReload(undoHubShipmentReceptionConfig);
      }
    }

    const isReceiveAndAssignShown =
      isDateValid && loadGuard && !!tryInt(selection?.is_candidate);

    const isCancelShown =
      isDateValid &&
      cancelGuard &&
      !!selection?.is_candidate &&
      !parseInt(selection.is_candidate);

    return generateContextMenu({
      t,
      onViewClick: showViewOrderDialog,
      isReceiveAndAssignShown,
      onReceiveAndAssignClick: receiveAndAssignShipment,
      isCancelShown,
      onCancelClick: handleCancelCMClick,
    });
  }, [
    cancelGuard,
    cancelShipmentReload,
    courierWorkOrderId,
    isDateValid,
    loadGuard,
    receiveAndAssignShipment,
    selection,
    showViewOrderDialog,
    t,
    toastRef,
    transferCourierWorkOrderId,
  ]);

  return (
    <CommonStateContext.Provider value={state}>
      <div className="page page-tramsfer-courier">
        <HeaderPages
          title={t('Transfer To Courier')}
          subtitle={t(
            'Receive orders from a Transfer Courier and simultaneously assign to a Courier for delivery'
          )}
          icon={faTruckLoading}
        />

        <ViewActiveOrderDialog
          data={orderData}
          visible={isViewOrderDialogVisible}
          onHide={hideViewOrderDialog}
          isLoading={isOrderDataLoading}
        />

        <WorkOrderNotCreatedDialog
          visible={isWorkOrderNotOpenDialogShown}
          onHide={handleWorkOrderNotOpenDialogHide}
          couriers={availableTransferCouriersCollectionRequest.data}
          courierFilter={transferCourierFilter}
        />

        <CreateWorkOrderDialog
          visible={isCreateWorkOrderDialogShown}
          onHide={handleCreateWorkOrderDialogHide}
          onSuccess={handleCreateWorkOrderDialogSuccess}
          couriers={availableCouriersCollectionRequest.data}
          courierFilter={courierFilter}
        />

        <Flex direction="column">
          <Filters
            availableTransferCouriersRequest={
              availableTransferCouriersCollectionRequest
            }
            availableCouriersRequest={availableCouriersCollectionRequest}
          />
          <DetailsSection>
            {loadGuard && (
              <Barcode onValidBarcodeScan={handleValidBarcodeScan} />
            )}

            <Stats
              transferCourierStatsRequest={transferCourierStatsRequest}
              courierStatsRequest={courierStatsRequest}
            />
          </DetailsSection>

          <MainContent>
            <Table
              tableRef={tableRef}
              columns={columns}
              data={
                hasTransferCourierWorkOrder && hasCourierWorkOrder
                  ? data
                  : undefined
              }
              isLoading={isLoading}
              hasError={!!error}
              filterHeight={360}
              reload={() =>
                sequential(reload, transferCourierStatsRequest.reload)
              }
              isReloadDisabled={
                !hasCourierWorkOrder || !hasTransferCourierWorkOrder
              }
              setPage={setPage}
              setLimit={setLimit}
              sortField={sortField}
              rows={limit}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
              setSelection={setSelection}
              sortOrder={sortOrder}
              selection={selection}
              storageString={tableStorageKey}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
              columnOptions={columnOptions}
              headerTitle={t('Shipments')}
              onRowDoubleClick={showViewOrderDialog}
              contextMenuModel={contextMenuModel}
              rowClassName={rowClassName}
            />
          </MainContent>
        </Flex>
      </div>
    </CommonStateContext.Provider>
  );
}

export default TransferCourierToCourier;
