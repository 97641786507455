import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

import { LabelValue } from '../../../types/options';
import { noColumnStyle } from '../../../utils/constants/tables';
import ActionsTableMenu from '../Components/ActionsTableMenu/ActionsTableMenu';

export const tableStorageKey = 'lockers_dataTable';

function getStatus(t: TFunction, status: number) {
  let color;
  let tooltip;

  if (status === 1) {
    color = 'var(--green-500)';
    tooltip = t('Active');
  } else if (status === 0) {
    color = 'var(--red-500)';
    tooltip = t('Inactive');
  } else {
    color = 'var(--gray-500)';
    tooltip = t('Unknown');
  }

  return (
    <span data-tip={tooltip} data-for="tooltip-right">
      <FontAwesomeIcon icon={faCircle} style={{ color }} />
    </span>
  );
}

export function lockerStatuses(t: TFunction): LabelValue[] {
  return [
    { label: t('Active'), value: '1' },
    { label: t('Inactive'), value: '0' },
  ];
}

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    online: t('Status'),
    title: t('Name'),
    address: t('Address'),
    municipalityName: t('Municipality'),
    postcode: t('Post Code'),
    numBoxes: t('Number of boxes'),
  };
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction: (t: string) => void,
  isViewShown: boolean
) {
  switch (column) {
    case 'no':
      return {
        noColumnStyle,
        style: {
          width: 30,
          maxWidth: 30,
        },
        bodyStyle: {
          width: 30,
          maxWidth: 30,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'online':
      return {
        body: (row: any) => {
          return getStatus(t, row[column]);
        },
        style: { width: 80, maxWidth: 110 },
        bodyStyle: {
          width: 80,
          maxWidth: 110,
          display: 'flex',
          justifyContent: 'center',
        },
      };

    case 'postcode':
    case 'numBoxes':
      return {
        style: {
          width: 110,
        },
        bodyStyle: {
          width: 110,
        },
      };

    case 'actions':
      return {
        body: (row: any) => {
          return (
            <ActionsTableMenu
              actions={[
                {
                  label: t('View'),
                  icon: 'fas fa-search',
                  disabled: !isViewShown,
                  command: () => {
                    setContextMenuSelection(row);
                    setAction('view-details');
                  },
                },
              ]}
            />
          );
        },
        style: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 60,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
          justifyContent: 'center',
        },
      };
    default:
      return {};
  }
}
