import './EnlargedImages.scss';

import { Dialog } from 'primereact/dialog';
import { useState } from 'react';

export function EnlargedImage({
  imagesUrls,
}: {
  imagesUrls: { id: number; path: any }[];
}) {
  const [enlargedImage, setEnlargedImage] = useState(null);

  return (
    <div className="enlarged-images">
      <div className="image-grid">
        {imagesUrls &&
          imagesUrls.map((image: any) => (
            <div className="image-grid-item" key={image.id}>
              <img
                src={image.path}
                alt={`-${image.id}`}
                onClick={() => setEnlargedImage(image.path)}
              />
            </div>
          ))}
      </div>

      <Dialog
        header="Image"
        visible={!!enlargedImage}
        style={{ width: '70vw', maxWidth: '800px' }}
        modal
        onHide={() => setEnlargedImage(null)}
      >
        {enlargedImage && (
          <img
            src={enlargedImage ?? ''}
            alt="Enlarged"
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
        )}
      </Dialog>
    </div>
  );
}
